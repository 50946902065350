import type { ReactElement, ReactNode } from 'react'
import { useMemo } from 'react'
import type { Editor } from 'tldraw'
import { TldrawEditor } from 'tldraw'
import 'tldraw/tldraw.css'
import { isFirePieceShape } from '../../annot/piece/box/shape'
import { createAndBindSegmentVerticalHeadShapes } from '../../annot/segment/vertical/head/create'
import { getSegmentVerticalHeadShapeFromFirePieceGroup, getSegmentVerticalHeadShapeFromFirePieceShape } from '../../annot/segment/vertical/head/shape'
import { isAnnotShape } from '../../annot/shape/shape'
import { useAttrs } from '../../attr/state/context'
import { editorBindingUtils } from '../binding/utils'
import { editorComponents } from '../component/components'
import { EditorEffects } from '../effect/effects'
import { editorShapeUtils } from '../shape/utils'
import { EDITOR_CAMERA } from '../util/camera'
import './provider.css'
import { createEditorTools } from './tools'

/**
 * When there are children inside, "tldraw editor" works as a provider.
 * It provides an "editor" instance for the whole app.
 * See "editor/canvas" for the canvas itself.
 */
export function EditorProvider(props: {
  children: ReactNode
}): ReactElement {
  const { children } = props

  // IMPORTANT: In practice, editor tools are created only once,
  // because "setAttrs" is stable. Never create editor tools dynamically.
  const { setAttrs } = useAttrs()
  const tools = useMemo(() => {
    return createEditorTools({ setAttrs })
  }, [setAttrs])

  return (
    <TldrawEditor
      // IMPORTANT: tldraw props must be stable,
      // so always define them outside or in useMemo.
      components={editorComponents}
      tools={tools}
      shapeUtils={editorShapeUtils}
      bindingUtils={editorBindingUtils}
      cameraOptions={EDITOR_CAMERA.options}
      onMount={editor => registerSideEffects(editor)}
    >
      <EditorEffects />
      {children}
    </TldrawEditor>
  )
}

function registerSideEffects(editor: Editor) {
  editor.sideEffects.registerBeforeChangeHandler('shape', (prev, next) => {
    if (isAnnotShape(prev) && prev.meta.interactive === 'ByAI')
      return { ...next, meta: { ...next.meta, interactive: 'ByBoth' } }
    return next
  })

  editor.sideEffects.registerAfterCreateHandler('shape', (shape) => {
    // When a fire piece is created, check if group has vertical length binding
    // and create corresponding vertical head.
    if (isFirePieceShape(shape)) {
      const existed = getSegmentVerticalHeadShapeFromFirePieceShape({
        editor,
        firePieceShape: shape,
      })

      if (existed)
        return

      const segmentVerticalHeadShape = getSegmentVerticalHeadShapeFromFirePieceGroup({
        editor,
        group: shape.meta.group,
      })

      if (!segmentVerticalHeadShape)
        return

      createAndBindSegmentVerticalHeadShapes({
        editor,
        firePieceShapes: [shape],
        group: segmentVerticalHeadShape.meta.group,
        mm: segmentVerticalHeadShape.meta.mm,
      })
    }
  })
}
