import { Text, makeStyles, tokens } from '@fluentui/react-components'
import type { ReactElement, ReactNode } from 'react'
import { useAttrs } from '../../../attr/state/context'
import { EditorShapeHighlight } from '../../../editor/shape/highlight'
import { getLineShapeEdgeRelative } from '../../../editor/shape/line'
import { useEditorTheme } from '../../../editor/util/theme'
import { EDITOR_ZOOM_VARIABLE } from '../../../editor/util/zoom'
import { usePage } from '../../../page/state/context'
import { useSetting } from '../../../setting/setting'
import type { SegmentFlatShape } from './shape'

const useStyles = makeStyles({
  label: {
    transform: 'translateX(-50%) translateY(-50%)',
    // "width" is extrinsic by default
    width: 'min-content',
    // "height" is intrinsic by default but better to be explicit
    height: 'min-content',
    // There is also an "opacity" set at the shape itself already
    backgroundColor: tokens.colorNeutralBackground1,
    padding: '0.25em',
    lineHeight: '1',
    fontWeight: 'bold',
  },
})

function useDisplay(props: {
  flat: SegmentFlatShape
}): {
  label: string
  size?: number
} | null {
  const { flat } = props

  const { scale } = usePage().page
  const { attrs } = useAttrs()
  const { segment, segmentSize } = useSetting().setting

  const size = segmentSize.type === 'fixed' ? segmentSize.px : undefined

  switch (segment) {
    case 'size': {
      const px = getLineShapeEdgeRelative(flat).length
      const mm = px * scale
      if (mm < 100)
        return null
      return { label: (mm / 1000).toFixed(2), size }
    }
    case 'shape': {
      // "attribute" is created with React's set state at the same time as
      // a "shape" is created in the editor, so it's expected that there are
      // brief moments when the "attribute" of an existing "group" is not
      // available yet.
      const attr = attrs[flat.meta.group]
      return attr && attr.shape ? { label: attr.shape, size } : null
    }
    case 'vertical':
    case 'none':
      return null
  }
}

export function SegmentFlatComponent(props: {
  original: ReactNode
  flat: SegmentFlatShape
}): ReactElement {
  const { original, flat } = props

  const s = useStyles()
  const display = useDisplay({ flat })
  const color = useEditorTheme()[flat.props.color]

  const center = getLineShapeEdgeRelative(flat).center

  return (
    <div>
      <EditorShapeHighlight shape={flat}>
        {original}
      </EditorShapeHighlight>
      {display && (
        // To position at the center of the edge (1)
        <div style={{ transform: `translate(${center.x}px, ${center.y}px)` }}>
          {/* To position the label at center of (1) */}
          <Text
            className={s.label}
            block // For positioning to work
            style={{
              color: color.solid,
              fontSize: display.size
                ? `${display.size}px`
                : `calc(16px / var(${EDITOR_ZOOM_VARIABLE}))`,
            }}
          >
            {display.label}
          </Text>
        </div>
      )}
    </div>
  )
}
