import { Button } from '@fluentui/react-components'
import { Eye16Regular, EyeOff16Regular, LocationTargetSquare20Regular } from '@fluentui/react-icons'
import { type ReactElement, useEffect } from 'react'
import { track, useEditor } from 'tldraw'
import { EditorToolButton } from '../../../editor/tool/button'
import { useSetting } from '../../../setting/setting'
import { t } from '../../../util/intl/t'
import { isZoneShape } from './shape'
import { PolygonZoneShapeTool } from './tool'

export const ZoneGroupButton = track((): ReactElement => {
  const editor = useEditor()

  const { setting, updateSetting } = useSetting()
  const zoneShapes = editor.getCurrentPageShapes().filter(isZoneShape)

  useEffect(() => {
    if (!setting.viewZone && editor.getCurrentToolId() === PolygonZoneShapeTool.id)
      editor.setCurrentTool('select')
  }, [setting.viewZone, editor])

  return (
    <>
      <EditorToolButton
        id={PolygonZoneShapeTool.id}
        icon={<LocationTargetSquare20Regular />}
        tooltip={!setting.viewZone ? t('annot.toolbar.select-zone-tooltip') : undefined}
        disabled={!setting.viewZone}
      >
        {t('annot.zone.label')}
      </EditorToolButton>

      <Button
        appearance="subtle"
        icon={setting.viewZone ? <EyeOff16Regular /> : <Eye16Regular />}
        onClick={() => {
          editor.mark()
          if (setting.viewZone) {
            editor.updateShapes(
              zoneShapes
                .map(shape => ({
                  ...shape,
                  isLocked: true,
                  opacity: 0,
                })),
            )
          }
          else {
            editor.updateShapes(zoneShapes
              .map(shape => ({
                ...shape,
                isLocked: false,
                opacity: 1,
              })),
            )
          }
          updateSetting({ viewZone: !setting.viewZone })
        }}

      >
        {setting.viewZone ? t('annot.zone.hide') : t('annot.zone.show')}
      </Button>
    </>
  )
})
