import type { TLEnterEventHandler, TLExitEventHandler, TLGeoShape, TLPointerEvent, TLShape } from 'tldraw'
import { GeoShapeTool } from 'tldraw'
import { isGeoShape } from '../../editor/shape/geo'
import { last } from '../../util/web/array'
import { getStrict } from '../../util/web/primitive'
import { isFirePieceShape } from '../piece/box/shape'
import { SEGMENT_EXTENSION_TOOL_ID } from '../segment/extension/tool'
import { isSegmentFlatShape } from '../segment/flat/shape'
import { SEGMENT_FLAT_TOOL_ID } from '../segment/flat/tool'
import { createAndBindSegmentVerticalHeadShapes } from '../segment/vertical/head/create'
import { getSegmentVerticalHeadShapeFromFirePieceGroup, getSegmentVerticalHeadShapeFromFirePieceShape } from '../segment/vertical/head/shape'
import { SEGMENT_VERTICAL_PIPE_TOOL_IDS } from '../segment/vertical/pipe/tool'
import type { AnnotShape, AnnotShapePartial } from './shape'
import { isAnnotShape } from './shape'

export type GeoToAnnot = (props: {
  geo: TLGeoShape
  prev: AnnotShape | null
}) => AnnotShapePartial

export abstract class AnnotGeoTool extends GeoShapeTool {
  abstract followTool: string | null

  override onEnter: TLEnterEventHandler = () => {
    // Locking the tool is not only a good UX but also a technical requirement
    // for us to customise the behaviour here, such as updating the new geo
    // shape to our annotation shape. This is also why it always runs no matter
    // the "follow tool" is set or not.
    //
    // In annotation, it's usually a better UX to lock the tool (i.e., keep it
    // active after each annotation). This allows the user to mark multiple
    // annotations consecutively and edit them all at once at the end.
    this.editor.updateInstanceState({ isToolLocked: true })

    const toolId = this.editor.getCurrentToolId()

    if (toolId === SEGMENT_VERTICAL_PIPE_TOOL_IDS.up
      || toolId === SEGMENT_VERTICAL_PIPE_TOOL_IDS.down
      || toolId === SEGMENT_EXTENSION_TOOL_ID) {
      const selectedShapes = this.editor.getSelectedShapes()
        .filter(isAnnotShape)
        .filter(isSegmentFlatShape)

      const currentSelected = last(selectedShapes) ?? null

      if (selectedShapes.length === 1 && currentSelected) {
        this.editor.setCurrentTool('line-select.line-to-geo', {
          shape: currentSelected,
          info: {
            direction: toolId === SEGMENT_VERTICAL_PIPE_TOOL_IDS.up ? 'up' : 'down',
            extension: toolId === SEGMENT_EXTENSION_TOOL_ID,
            onInteractionEnd: SEGMENT_FLAT_TOOL_ID,
          },
          onInteractionEnd: SEGMENT_FLAT_TOOL_ID,
        })
      }
    }
  }

  override onExit: TLExitEventHandler = () => {
    // As we locked the tool on enter, we should always reset it on exit. This
    // prevents one tool from interfering with another's lock behaviour.
    this.editor.updateInstanceState({ isToolLocked: false })
    this.editor.mark()
  }

  override onPointerMove: TLPointerEvent = () => {
    // Turn off the built-in "drag to create" behaviour.
    //
    // This is defined at the "geo shape tool"'s "pointing" child, which we
    // don't have access to (for good reasons).
    //
    // However, we can cancel the behaviour by transitioning here. Because
    // parent always handles events before children, this prevents the
    // "pointing" child's "on pointer move" from running.
    this.transition('idle')
  }

  /**
   * Check if the given shape could be used as a previous annotation to create
   * the new annotation. The new annotation will use the "group" and "color"
   * from the previous annotation to _continue_ an ongoing group (e.g.,
   * pipeline).
   *
   * For example, a "flat segment" could be a previous shape for a "vertical
   * segment", but not for a "piece".
   */
  abstract isPrev(shape: TLShape): boolean

  /**
   * Convert a newly created geo shape to an annotation shape.
   *
   * Technically, we don't need to pass the previous annotation shape, as we can
   * update the annotation's group and color here, after it is converted.
   * However, most annotation tools encapsulate their creating, which requires
   * group and color in the first place.
   */
  abstract toAnnot: GeoToAnnot

  /**
   * Create an attribute for the new annotation _group_. This is necessary when
   * there is no previous annotation to continue, so we are starting a new
   * group.
   */
  abstract createAttr(group: string): void

  override onPointerUp: TLPointerEvent = () => {
    // There are 2 phases at the "on pointer up": before and after the child
    // "pointing" tool handles the event (to create the shape).

    // At this point, the "pointing" tool has not handled the event yet, so if
    // the user selects a previous annotation, we can access it here.
    const prev: AnnotShape | null = this.editor
      .getSelectedShapes()
      .filter(isAnnotShape)
      .filter(this.isPrev)
      .at(0) ?? null

    window.setTimeout(() => {
      // At this point, the "pointing" tool has handled the event. This means
      // a new geo shape has been created _and_ selected. We will update it to
      // our annotation shape.
      const shapes = this.editor.getSelectedShapes()
      // It's expected if there are no shapes selected, because the user could
      // cancel the annotation. For example, see our "on pointer move" above.
      if (shapes.length === 0)
        return
      // However, it's not expected that there are more than 1 shape selected.
      // The tool should be able to create only 1 shape at a time.
      if (shapes.length > 1)
        throw new Error(`Expected 1 shape, but received "${shapes.length}".`)
      const geo = getStrict(shapes.at(0))
      // It's also a coding error if the selected shape is not a geo shape, as
      // we are in a geo shape tool.
      if (isGeoShape(geo) === false)
        throw new Error(`Expected a geo shape, but received "${geo.type}".`)

      const annot = this.toAnnot({ geo, prev })
      if (prev === null)
        this.createAttr(annot.meta.group)
      this.editor.updateShape(annot)

      // Passing the control to another tool. This requires the editor tool to
      // be locked, so that tldraw does not transition to the "select" tool
      // itself at "pointing"'s "on pointer up".
      if (this.followTool !== null)
        this.editor.setCurrentTool(this.followTool)

      /// When a fire piece is created, check if group has vertical length binding
      // and create corresponding vertical head.
      const annotShape = this.editor.getShape(annot.id)
      if (annotShape && isFirePieceShape(annotShape)) {
        const existed = getSegmentVerticalHeadShapeFromFirePieceShape({
          editor: this.editor,
          firePieceShape: annotShape,
        })

        if (existed)
          return

        const segmentVerticalHeadShape = getSegmentVerticalHeadShapeFromFirePieceGroup({
          editor: this.editor,
          group: annotShape.meta.group,
        })

        if (!segmentVerticalHeadShape)
          return

        createAndBindSegmentVerticalHeadShapes({
          editor: this.editor,
          firePieceShapes: [annotShape],
          group: segmentVerticalHeadShape.meta.group,
          mm: segmentVerticalHeadShape.meta.mm,
        })
      }
    }, 0)
  }
}
