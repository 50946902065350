import { Box } from 'tldraw'
import type { AttrRecord } from '../../../attr/state/context'
import type { SetState } from '../../../util/react/state'
import type { GeoToAnnot } from '../../shape/geo'
import { AnnotGeoTool } from '../../shape/geo'
import type { AnnotShape } from '../../shape/shape'
import { createPieceCheckAttr, createPieceCheckShape } from './create'
import { isPieceCheckShape } from './shape'

export const MECHANICAL_PIECE_TOOL_ID = 'mechanical-piece-check-box'

export function createMechanicalPieceTool(props: {
  setAttrs: SetState<AttrRecord>
}) {
  const { setAttrs } = props

  return class MechanicalPieceTool extends AnnotGeoTool {
    static override id = MECHANICAL_PIECE_TOOL_ID

    override followTool = null

    // @TODO: This should enforce prev's type?
    override isPrev(shape: AnnotShape) {
      return isPieceCheckShape(shape)
    }

    override createAttr(group: string): void {
      setAttrs(attrs => ({
        ...attrs,
        [group]: createPieceCheckAttr(),
      }))
    }

    override toAnnot: GeoToAnnot = (props) => {
      const { geo, prev } = props

      const geoBox = new Box(geo.x, geo.y, geo.props.w, geo.props.h)
      const center = geoBox.center

      const check = createPieceCheckShape({
        box: Box.FromCenter(center, { x: 10, y: 10 }),
        id: geo.id,
        color: prev?.props.color ?? null,
        group: prev?.meta.group ?? null,
        interactive: 'ByManual',
        zoneID: '',
      })

      return check
    }
  }
}
