import { type RefObject, useEffect } from 'react'
import { preventDefault, useContainer } from 'tldraw'

// https://github.com/tldraw/tldraw/pull/4662
export function usePassThroughWheelEvents(ref: RefObject<HTMLElement>) {
  if (!ref)
    throw new Error('usePassThroughWheelEvents must be passed a ref')

  const container = useContainer()

  useEffect(() => {
    function onWheel(e: WheelEvent) {
      if ((e as any).isSpecialRedispatchedEvent)
        return
      preventDefault(e)
      const cvs = container.querySelector('.tl-canvas')
      if (!cvs)
        return
      const newEvent = new WheelEvent('wheel', e as any)
        ; (newEvent as any).isSpecialRedispatchedEvent = true
      cvs.dispatchEvent(newEvent)
    }

    const elm = ref.current
    if (!elm)
      return

    elm.addEventListener('wheel', onWheel, { passive: false })
    return () => {
      elm.removeEventListener('wheel', onWheel)
    }
  }, [container, ref])
}
