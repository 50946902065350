import { useEffect } from 'react'
import type { Editor, TLGeoShape, VecLike } from 'tldraw'
import { Box, Vec, intersectLineSegmentPolygon, pointInPolygon } from 'tldraw'
import { makeSegment } from '../../annot/cut/tool'
import type { ZoneShape } from '../../annot/polygon/zone/shape'
import { isClosedZoneShape } from '../../annot/polygon/zone/shape'
import type { SegmentFlatShape } from '../../annot/segment/flat/shape'
import { isSegmentFlatShape } from '../../annot/segment/flat/shape'
import { isSegmentVerticalHeadShape } from '../../annot/segment/vertical/head/shape'
import type { AnnotShape } from '../../annot/shape/shape'
import { isAnnotShape } from '../../annot/shape/shape'
import type { AttrRecord } from '../../attr/state/context'
import { useAttrs } from '../../attr/state/context'
import { SEGMENT_FLAT_INDICES, cloneAttrs } from '../../predict/fetch/polygon-crop'
import { linePointsToArray } from '../../predict/polygon-area/util'
import type { SetState } from '../../util/react/state'
import { isGeoShape } from '../shape/geo'
import { getLineShapeEdgeAbsolute } from '../shape/line'

function cutSegmentRegression(props: {
  shape: SegmentFlatShape
  intersections: VecLike[]
}): SegmentFlatShape[] {
  const { shape, intersections } = props

  if (intersections.length === 0)
    return [shape]

  // Clone the first intersection to avoid mutating the original
  const firstIntersection = intersections[0]
  const vec = firstIntersection as Vec
  vec.sub(shape)

  const { start, end } = SEGMENT_FLAT_INDICES

  // Create new segments
  const segments = [
    makeSegment({ original: shape, vec, index: end }),
    makeSegment({ original: shape, vec, index: start }),
  ]
  // Recursively process remaining intersections
  const remainingIntersections = intersections.slice(1)
  return [
    segments[0],
    ...cutSegmentRegression({ shape: segments[1], intersections: remainingIntersections }),
  ]
}

function assignZoneToAnnots(props: {
  editor: Editor
  shape: AnnotShape
  setAttrs: SetState<AttrRecord>
  zoneShapes: ZoneShape[]
}) {
  const { editor, shape, setAttrs, zoneShapes } = props

  zoneShapes.forEach((zone) => {
    const points = linePointsToArray(zone).map(Vec.From)
    const transform = points.map(point => ({ x: point.x + zone.x, y: point.y + zone.y }))
    if (zone.meta.zoneId === '')
      return

    if (isSegmentFlatShape(shape)) {
      const { start, end } = getLineShapeEdgeAbsolute(shape)

      const intersections = intersectLineSegmentPolygon(start, end, transform)
      if (intersections) {
        intersections.sort((a, b) => {
          if (a.x === b.x)
            return a.y - b.y

          return a.x - b.x
        })

        const segments = cutSegmentRegression({ shape, intersections })

        setAttrs(prev => cloneAttrs({ prev, changes: segments }))

        editor.createShapes(segments)

        editor.deleteShapes([shape])
        editor.mark()

        segments.forEach((add) => {
          const edge = getLineShapeEdgeAbsolute(add).vertices
          const [start, end] = edge

          if (Vec.Dist2(start, end) === 0) {
            editor.deleteShapes([add])
            editor.mark()
          }

          if (pointInPolygon(start, transform) && pointInPolygon(end, transform)) {
            const next: SegmentFlatShape = {
              ...add,
              meta: {
                ...add.meta,
                zoneID: zone.meta.zoneId,
                zoneShapeId: zone.id,
              },
            }
            editor.updateShape(next)
          }
        })
      }
      // flat shape completely inside polygon
      else {
        const edge = getLineShapeEdgeAbsolute(shape).vertices

        const [start, end] = edge
        if (pointInPolygon(start, transform) && pointInPolygon(end, transform)) {
          const next: SegmentFlatShape = {
            ...shape,
            meta: {
              ...shape.meta,
              zoneID: zone.meta.zoneId,
              zoneShapeId: zone.id,
            },
          }
          editor.updateShape(next)
        }
      }
    }

    if (isGeoShape(shape)) {
      if (isSegmentVerticalHeadShape(shape)) {
        const center = new Box(shape.x, shape.y, shape.props.w, shape.props.h).center
        const inside = pointInPolygon(center, transform)
        if (inside) {
          editor.updateShape({ ...shape, isLocked: false })

          const next: TLGeoShape = {
            ...shape,
            meta: {
              ...shape.meta,
              zoneID: zone.meta.zoneId,
              zoneShapeId: zone.id,
            },
            isLocked: true,
          }
          editor.updateShape(next)
        }
        return
      }

      const center = new Box(shape.x, shape.y, shape.props.w, shape.props.h).center
      const inside = pointInPolygon(center, transform)
      if (inside) {
        const next: TLGeoShape = {
          ...shape,
          meta: {
            ...shape.meta,
            zoneID: zone.meta.zoneId,
            zoneShapeId: zone.id,
          },
        }
        editor.updateShape(next)
      }
    }
  })
}

function unassignZoneToAnnots(props: {
  editor: Editor
  shape: AnnotShape
  zoneShapes: ZoneShape[]
}) {
  const { editor, shape, zoneShapes } = props

  zoneShapes.forEach((zone) => {
    const points = linePointsToArray(zone).map(Vec.From)
    const transform = points.map(point => ({ x: point.x + zone.x, y: point.y + zone.y }))

    // check if the zone is unassigned or if the zone is transforming
    if (zone.meta.zoneId === '')
      return

    if (isSegmentFlatShape(shape)) {
      const { start, end } = getLineShapeEdgeAbsolute(shape)
      if (!(pointInPolygon(start, transform) && pointInPolygon(end, transform)) && shape.meta.zoneShapeId === zone.id) {
        editor.updateShape({
          ...shape,
          meta: {
            ...shape.meta,
            zoneID: '',
            zoneShapeId: '',
          },
        })
        editor.mark()
      }
    }

    if (isGeoShape(shape)) {
      if (isSegmentVerticalHeadShape(shape)) {
        const center = new Box(shape.x, shape.y, shape.props.w, shape.props.h).center
        const inside = pointInPolygon(center, transform)
        if (!inside && shape.meta.zoneShapeId === zone.id) {
          editor.updateShape({ ...shape, isLocked: false })

          const next: TLGeoShape = {
            ...shape,
            meta: {
              ...shape.meta,
              zoneID: '',
              zoneShapeId: '',
            },
            isLocked: true,
          }
          editor.updateShape(next)
        }
        return
      }

      const center = new Box(shape.x, shape.y, shape.props.w, shape.props.h).center
      const inside = pointInPolygon(center, transform)
      if (!inside && shape.meta.zoneShapeId === zone.id) {
        editor.updateShape({ ...shape, meta: { ...shape.meta, zoneID: '', zoneShapeId: '' } })
        editor.mark()
      }
    }
  })
}

export function useEditorTransformShapes(props: { editor: Editor }): void {
  const { editor } = props
  const { setAttrs } = useAttrs()

  const currentZones = editor.getCurrentPageShapes().filter(isClosedZoneShape)
  const currentShapes = editor.getCurrentPageShapes().filter(isAnnotShape)

  useEffect(() => {
    if (currentZones.length === 0 || currentShapes.length === 0)
      return

    const id = window.setTimeout(() => {
      currentShapes.forEach((shape) => {
        assignZoneToAnnots({ editor, shape, setAttrs, zoneShapes: currentZones })
        unassignZoneToAnnots({ editor, shape, zoneShapes: currentZones })
      })
    }, 2000)

    return () => {
      window.clearTimeout(id)
    }
  }, [currentZones, editor, setAttrs, currentShapes])
}
