import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { usePage } from '../../page/state/context'
import { server } from '../../util/data/server'
import type { CreateZoneValues } from './zone/dropdown'

const queryKey = 'zone'

export function useCreateZone() {
  const { page } = usePage()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (update: CreateZoneValues) => server.createZone(page.constructionID, update),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [queryKey, 'list'] })
    },
  })
}
export function useListZone() {
  const { page } = usePage()

  return useQuery({
    queryKey: [queryKey, 'list'],
    queryFn: () => server.listZones(page.constructionID, 0, 200),
  })
}
