import { Caption1, Divider, makeStyles, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { usePage } from '../../page/state/context'
import { t } from '../../util/intl/t'
import { isPredictAreaShape } from '../area/shape'
import { isPredictPolygonAreaShape } from '../polygon-area/shape'
import { PredictParamForm } from './form'
import { useGetGlobalAIModelParams, useListContructionAIModelParams } from './value'

const AI_MODEL_PARAMETERS = 1

const useStyles = makeStyles({
  column: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalM,
  },
  help: {
    color: tokens.colorNeutralForeground4,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalM,
  },
  divider: {
    margin: `${tokens.spacingHorizontalS} 0`,
  },
})

export const PredictParamDetail = track((): ReactElement | null => {
  const editor = useEditor()

  const constructionID = usePage().page.constructionID

  const area = editor.getOnlySelectedShape()

  const { data: constructionModel, isSuccess } = useListContructionAIModelParams(constructionID)

  const constructionParams = (constructionModel ?? []).find(param => param.aiModelID === AI_MODEL_PARAMETERS)

  const globalParams = useGetGlobalAIModelParams(AI_MODEL_PARAMETERS)

  const params = isSuccess && constructionParams === undefined ? globalParams : constructionParams
  const selectMode = editor.getCurrentToolId() === 'select'

  const s = useStyles()

  if (!selectMode)
    return null

  if (area === null || (!isPredictAreaShape(area) && !isPredictPolygonAreaShape(area)) || params === undefined)
    return null

  return (
    <div key={area.id}>
      <form>
        <div className={s.column}>
          <Caption1 className={s.help}>{t('predict.params.select-hint-1')}</Caption1>
          <Caption1 className={s.help}>{t('predict.params.select-hint-2')}</Caption1>
        </div>
        <div className={s.divider}>
          <Divider />
        </div>
        <div className={s.form}>
          <PredictParamForm defaultValues={params} area={area} />
        </div>
      </form>
    </div>
  )
})
