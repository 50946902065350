import type {
  BindingOnShapeChangeOptions,
  BindingOnShapeDeleteOptions,
  TLBaseBinding,
} from 'tldraw'
import { BindingUtil, lerp } from 'tldraw'
import type { SegmentVerticalHeadShape } from '../../annot/segment/vertical/head/shape'

export const VERTICAL_HEAD_BINDING_TYPE = 'vertical-head' as const

export type VerticalHeadBinding = TLBaseBinding<typeof VERTICAL_HEAD_BINDING_TYPE, Record<string, never>>

// References: https://tldraw.dev/examples/shapes/tools/sticker-bindings
export class VerticalHeadBindingUtil extends BindingUtil<VerticalHeadBinding> {
  static override type = VERTICAL_HEAD_BINDING_TYPE

  override getDefaultProps() {
    return {}
  }

  // when the shape we're stuck to changes, update segment vertical head's position
  override onAfterChangeToShape({
    binding,
    shapeAfter,
  }: BindingOnShapeChangeOptions<VerticalHeadBinding>): void {
    const segmentVerticalHeadShape = this.editor.getShape<SegmentVerticalHeadShape>(binding.fromId)!

    // Unlock segment vertical head shape for update
    this.editor.updateShape({ ...segmentVerticalHeadShape, isLocked: false })

    const shapeBounds = this.editor.getShapeGeometry(shapeAfter)!.bounds
    const shapeAnchor = {
      x: lerp(shapeBounds.minX, shapeBounds.maxX, 0.5),
      y: lerp(shapeBounds.minY, shapeBounds.maxY, 1),
    }
    const pageAnchor = this.editor
      .getShapePageTransform(shapeAfter)
      .applyToPoint(shapeAnchor)

    const segmentVerticalHeadParentAnchor = this.editor
      .getShapeParentTransform(segmentVerticalHeadShape)
      .invert()
      .applyToPoint(pageAnchor)

    // Update segment vertical head shape and lock it to prevent manual update
    this.editor.updateShape({
      ...segmentVerticalHeadShape,
      x: segmentVerticalHeadParentAnchor.x,
      y: segmentVerticalHeadParentAnchor.y,
      isLocked: true,
    })
  }

  // when the thing we're stuck to is deleted, delete segment vertical head too
  override onBeforeDeleteToShape({
    binding,
  }: BindingOnShapeDeleteOptions<VerticalHeadBinding>): void {
    // Unlock segment vertical head shape for deletion
    this.editor.updateShape({ id: binding.fromId, type: 'geo', isLocked: false })
    this.editor.deleteShape(binding.fromId)
  }
}
