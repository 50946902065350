import type { TLDefaultColorStyle } from 'tldraw'
import { t } from '../../util/intl/t'
import { randomChild } from '../../util/web/array'

export type AnnotShapeColorOption = {
  label: string
  value: TLDefaultColorStyle
}

/**
 * We should keep this list sorted by rainbow, as it is used in the UI as
 * options in a dropdown for users to choose from.
 */
export const ANNOT_SHAPE_COLOR: TLDefaultColorStyle[] = [
  'red',
  'orange',
  'yellow',
  'green',
  'light-blue',
  'blue',
  'violet',
  'light-violet',
  'light-red',
]

/**
 * This is intentionally narrow than TLDraw's colors. See comment at
 * isShapeColor to learn more.
 */
export function randomAnnotShapeColor() {
  return randomChild(ANNOT_SHAPE_COLOR)
}

export const ANNOT_SHAPE_COLOR_OPTIONS: AnnotShapeColorOption[] = [
  { label: t('annot.color.red'), value: 'red' },
  { label: t('annot.color.orange'), value: 'orange' },
  { label: t('annot.color.yellow'), value: 'yellow' },
  { label: t('annot.color.green'), value: 'green' },
  { label: t('annot.color.lightBlue'), value: 'light-blue' },
  { label: t('annot.color.blue'), value: 'blue' },
  { label: t('annot.color.violet'), value: 'violet' },
  { label: t('annot.color.lightViolet'), value: 'light-violet' },
  { label: t('annot.color.lightRed'), value: 'light-red' },
]
