import type { Editor, TLGeoShape, TLShape } from 'tldraw'
import { ATTR_EQUIP_VALUES } from '../../../../attr/field/equip/value'
import type { AttrValue } from '../../../../attr/state/context'
import { ATTR_FIELDS_DEFAULT } from '../../../../attr/state/context'
import { VERTICAL_HEAD_BINDING_TYPE } from '../../../../editor/binding/vertical-head'
import type { EditorShapePartial } from '../../../../editor/shape/base'
import type { VerticalDirection } from '../../../../util/data/server'
import { type PieceBoxShape, isFirePieceShape } from '../../../piece/box/shape'
import type { SegmentMetaBase } from '../../shape'
import { isSegmentShapeBase } from '../../shape'

type Meta = SegmentMetaBase & {
  segment: 'vertical'
  vertical: 'head'
  mm: number
  direction: VerticalDirection
  firePiece: string
}

export type SegmentVerticalHeadShape = TLGeoShape & { meta: Meta }

export type SegmentVerticalHeadPartial = EditorShapePartial<TLGeoShape, Meta>

export function isSegmentVerticalHeadShape(
  shape: TLShape,
): shape is SegmentVerticalHeadShape {
  if (!isSegmentShapeBase(shape))
    return false
  const test = shape as SegmentVerticalHeadShape
  return test.meta.segment === 'vertical' && test.meta.vertical === 'head'
}

export function createSegmentVerticalHeadAttr(): AttrValue {
  return {
    ...(ATTR_FIELDS_DEFAULT),
    equip: ATTR_EQUIP_VALUES.FIRE_PROTECTION_PIPE,
  }
}

export function getSegmentVerticalHeadShapeFromFirePieceShape(props: {
  editor: Editor
  firePieceShape: PieceBoxShape
}): SegmentVerticalHeadShape | undefined {
  const { editor, firePieceShape } = props

  if (!isFirePieceShape(firePieceShape))
    throw new Error('firePieceShape is not a fire piece')

  const verticalHeadBinding = editor.getBindingsToShape(firePieceShape.id, VERTICAL_HEAD_BINDING_TYPE)
  if (verticalHeadBinding.length > 1)
    throw new Error('Each fire piece should only have one vertical head binding')

  const segmentVerticalHeadShape = verticalHeadBinding.length === 1
    ? editor.getShape(verticalHeadBinding[0].fromId)
    : undefined
  if (segmentVerticalHeadShape !== undefined && !isSegmentVerticalHeadShape(segmentVerticalHeadShape))
    throw new Error('Vertical head binding should point to a vertical head shape')

  return segmentVerticalHeadShape
}

export function getSegmentVerticalHeadShapeFromFirePieceGroup(props: {
  editor: Editor
  group: string
}): SegmentVerticalHeadShape | undefined {
  const { editor, group } = props

  const firePieceShapes = editor.getCurrentPageShapes()
    .filter(isFirePieceShape)
    .filter(firePiece => firePiece.meta.group === group)

  const segmentVerticalHeadShape = firePieceShapes
    .map(firePieceShape => getSegmentVerticalHeadShapeFromFirePieceShape({ editor, firePieceShape }))
    .find((shape): shape is SegmentVerticalHeadShape => !!shape)

  return segmentVerticalHeadShape
}

export function getAttrsFromFirePieceAttrs(attr: AttrValue | undefined): Partial<AttrValue> {
  if (!attr)
    return {}

  const { equip } = attr
  switch (equip) {
    case ATTR_EQUIP_VALUES.SPRINKLER_HEAD:
    case ATTR_EQUIP_VALUES.SPRINKLER_HEAD_50:
      return { shape: '25' }
    case ATTR_EQUIP_VALUES.CX_HEAD_III:
    case ATTR_EQUIP_VALUES.FOAM_HEAD_YFH59:
    case ATTR_EQUIP_VALUES.FOAM_HEAD_NORMAL:
      return { shape: '20' }
    case ATTR_EQUIP_VALUES.FIRE_HYDRANT:
      return { shape: '32' }
  }

  return {}
}
