import type { TLGeoShape, TLShape } from 'tldraw'
import type { EditorShapePartial } from '../../../../editor/shape/base'
import type { VerticalDirection } from '../../../../util/data/server'
import type { SegmentMetaBase } from '../../shape'
import { isSegmentShapeBase } from '../../shape'

type Meta = SegmentMetaBase & {
  segment: 'vertical'
  vertical: 'pipe'
  mm: number
  direction: VerticalDirection
}

export type SegmentVerticalPipeShape = TLGeoShape & { meta: Meta }

export type SegmentVerticalPipePartial = EditorShapePartial<TLGeoShape, Meta>

export function isSegmentVerticalPipeShape(
  shape: TLShape,
): shape is SegmentVerticalPipeShape {
  if (!isSegmentShapeBase(shape))
    return false
  const test = shape as SegmentVerticalPipeShape
  return test.meta.segment === 'vertical' && test.meta.vertical === 'pipe'
}
