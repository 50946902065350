import { SpinButton, ToolbarDivider, makeStyles } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import type { TLShape } from 'tldraw'
import { track, useEditor } from 'tldraw'
import { onSpinButtonChange } from '../../../ui/spin-button'
import { ToolbarLabel } from '../../../ui/toolbar/label'
import { t } from '../../../util/intl/t'
import { last } from '../../../util/web/array'
import { isSegmentExtensionShape } from '../extension/shape'
import type { SegmentShape } from '../shape'
import { isSegmentShape } from '../shape'
import { isSegmentVerticalPipeShape } from '../vertical/pipe/shape'

const useStyles = makeStyles({
  input: {
    width: '50px',
  },
})

function findLatestContinuousPair(shapes: SegmentShape[], selected: TLShape | null): [SegmentShape, SegmentShape] | null {
  if (!selected)
    return null

  const resultPairs: [SegmentShape, SegmentShape][] = []

  for (let i = 0; i < shapes.length - 1; i++) {
    const currentShape = shapes[i]
    const nextShape = shapes[i + 1]

    const isFirstPair = i === 0 && currentShape.type === 'geo' && nextShape.type === 'line' && nextShape.id === selected.id
    const isContinuousPair = currentShape.type === 'line' && nextShape.type === 'geo' && currentShape.id === selected.id

    if (isFirstPair || isContinuousPair)
      resultPairs.push([currentShape, nextShape])
  }

  return last(resultPairs) ?? null
}

export const SegmentGeoLength = track((): ReactElement | null => {
  const editor = useEditor()
  const s = useStyles()

  const shape = editor.getOnlySelectedShape()

  const selected = editor.getSelectedShapes().filter(isSegmentShape)

  const groupedShapes = editor.getCurrentPageShapes()
    .filter(isSegmentShape)
    .filter(shape => selected.some(segment => segment.meta.group === shape.meta.group))

  // To enable input length, we need to find the latest pair of flat and vertical segments.
  // Cause we create continuous segments and verticals/extensions
  const continuous = findLatestContinuousPair(groupedShapes, shape) ?? []

  const vertical = continuous.find(segment => segment.type === 'geo') ?? null

  const segment = shape && isSegmentVerticalPipeShape(shape) ? shape : vertical

  if (segment === null)
    return null

  if (!isSegmentExtensionShape(segment) && !isSegmentVerticalPipeShape(segment))
    return null

  const setMeter = (meter: number): void => {
    const meta = { ...segment.meta, mm: meter * 1_000 }
    editor.updateShape({ ...segment, meta })
  }

  return (
    <>
      <ToolbarDivider />
      <ToolbarLabel>{t('segment.geo.length')}</ToolbarLabel>
      <SpinButton
        value={segment.meta.mm / 1_000}
        onChange={onSpinButtonChange(value => setMeter(value))}
        appearance="filled-darker"
        input={{ className: s.input }}
        min={0}
        step={0.1}
      />
    </>
  )
})
