import type { ReactElement, ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { KYUDENKO_ORG_PROD, KYUDENKO_ORG_QA } from '../../predict/model/select'
import { useSetting } from '../../setting/setting'
import type { PageLimit } from '../state/context'
import { pageContext } from '../state/context'
import { usePageAnnots } from './annot'
import { usePageDetail } from './detail'
import { usePageMetadata } from './metadata'
import { usePagePdf } from './pdf'

export function PageProvider(props: {
  children: ReactNode
  Pending: (props: { progress: number }) => ReactElement
  Error: (props: { error: Error }) => ReactElement
}): ReactElement {
  const { children, Error, Pending } = props

  const pdf = usePagePdf()
  /* Make sure we call usePageDetail after retrieving the PDF
  This is because we called "getPageDetail" twice:
    - once to get page info (omitPDF = true)
    - and once to retrieve the PDF.
  Due to uncertainty about which request completes first,
  there is a risk of displaying an inaccurate view limit counter */
  const detail = usePageDetail({ enable: pdf.query.isSuccess })
  const annots = usePageAnnots()

  const metadata = usePageMetadata()
  /**
   * @deprecated This is a bad design from our past.
   * The "page" endpoint should be treated as 2 separate data sources:
   * 1. One for fields that will never change, such as the PDF
   * 2. One for fields that will change often, such as AI limit
   * We should put the first one into a global context,
   * while keeping the second one in a React query.
   */
  const [limit, setLimit] = useState<PageLimit | null>(null)

  const { setting, updateSetting } = useSetting()

  // @TODO: Update predict model setting according to the system supported by org.
  useEffect(() => {
    if (detail.query.data) {
      const orgID = detail.query.data.orgID

      if (orgID === KYUDENKO_ORG_PROD || orgID === KYUDENKO_ORG_QA) {
        if (!['duct', 'drain', 'electrical', 'fire-head', 'fire-pipe', 'fire-pipe-diameter'].includes(setting.predictModel))
          updateSetting({ predictModel: 'electrical' })
      }
    }
  }, [detail.query.data, setting.predictModel, updateSetting])

  if (detail.query.data && pdf.query.data && annots.data && metadata.data) {
    const aiPredictionRemaining = limit ? limit.aiPredictionRemaining : detail.query.data.aiPredictionRemaining

    return (
      <pageContext.Provider
        value={{
          page: detail.query.data,
          updatePage: detail.mutation,
          pdf: pdf.query.data,
          annots: annots.data,
          limit: { aiPredictionRemaining: aiPredictionRemaining < 0 ? 0 : aiPredictionRemaining },
          setLimit,
          zones: metadata.data ?? null,
        }}
      >
        {children}
      </pageContext.Provider>
    )
  }

  const error = [detail.query, pdf.query, annots]
    .map(result => result.error)
    .find(error => error !== null) ?? null
  if (error !== null)
    return <Error error={error} />

  return <Pending progress={pdf.progress} />
}
