import type { TLStateNodeConstructor } from 'tldraw'
import { StateNode } from 'tldraw'
import { Idle } from './tool-state/idle'
import { Moving } from './tool-state/moving'
import { Pointing } from './tool-state/pointing'

export class PolygonZoneShapeTool extends StateNode {
  static override id = 'zone'
  static override initial = 'idle'
  static override children = (): TLStateNodeConstructor[] => [Idle, Pointing, Moving]
  override shapeType = 'polygon'
}
