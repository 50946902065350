import { Box, DefaultColorStyle, shapeIdValidator } from 'tldraw'
import type { AnnotationData, PipeLineSegment, PipeLineSegmentExtension } from '../../../util/data/server'
import { createSegmentExtensionShape } from './create'
import type { SegmentExtensionPartial, SegmentExtensionShape } from './shape'

export function toServerSegmentExtension(
  client: SegmentExtensionShape,
): PipeLineSegment {
  const box = new Box(client.x, client.y, client.props.w, client.props.h)

  const extension: PipeLineSegmentExtension = {
    length: client.meta.mm,
    point: { x: box.center.x, y: box.center.y },
  }

  const server: PipeLineSegment = {
    type: 'Extension',
    uuid: client.id,
    inner: extension,
    interactive: client.meta.interactive !== null
      ? client.meta.interactive
      : undefined,
    zoneID: client.meta.zoneID !== '' ? client.meta.zoneID : undefined,
  }

  return server
}

export function fromServerSegmentExtension(props: {
  server: PipeLineSegmentExtension
  segment: PipeLineSegment
  group: AnnotationData
}): SegmentExtensionPartial {
  const { group, server, segment } = props

  const extension = createSegmentExtensionShape({
    center: server.point,
    mm: server.length,
    id: shapeIdValidator.validate(segment.uuid),
    color: DefaultColorStyle.validate(group.colorName),
    group: group.uuid,
    interactive: segment.interactive ?? null,
    zoneID: segment.zoneID ?? '',
  })

  return extension
}
