import { z } from 'zod'
import { segmentLabelSchema } from '../annot/segment/label/type'
import { segmentSizeSchema } from '../annot/segment/size/type'
import { appPrimaryTabSchema, appSecondaryTabSchema, appToolbarTabSchema } from '../app/panel/tab'
import { predictModelValueSchema } from '../predict/model/option'
import { useDataLocal } from '../util/data/local'

const themeSchema = z.enum(['light', 'dark'])

const schema = z.object({
  toolbar: appToolbarTabSchema,
  primary: appPrimaryTabSchema,
  secondary: appSecondaryTabSchema,
  segment: segmentLabelSchema,
  segmentSize: segmentSizeSchema,
  theme: themeSchema,
  autoInsul: z.boolean(),
  predictModel: predictModelValueSchema,
  viewZone: z.boolean(),
})

export type Setting = z.infer<typeof schema>

const fallback: Setting = {
  toolbar: 'predict',
  primary: 'result',
  secondary: 'attr',
  segment: 'size',
  segmentSize: { type: 'scale' },
  theme: 'light',
  autoInsul: true,
  predictModel: 'duct',
  viewZone: true,
}

export function useSetting(): {
  setting: Setting
  updateSetting: (update: Partial<Setting>) => void
} {
  const [setting, updateSetting] = useDataLocal<Setting>({
    queryKey: ['setting'],
    localKey: 'setting',
    fallback,
    schema,
  })
  return { setting, updateSetting }
}
