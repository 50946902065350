import { Divider, Text, makeStyles, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { t } from '../../../../util/intl/t'
import { isFirePieceShape } from '../../../piece/box/shape'
import { SegmentVerticalHeadAttrs } from './attrs'
import { SegmentVerticalHeadLength } from './length'
import type { SegmentVerticalHeadShape } from './shape'
import { getSegmentVerticalHeadShapeFromFirePieceShape } from './shape'

const useStyles = makeStyles({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // Technically we only need to "fix" the bottom margin,
    // but it's easier to balance the spacing by fixing both.
    // Choosing XS here because it plays nicely with the "enabled" checkbox.
    margin: `calc(-1 * ${tokens.spacingVerticalXS}) 0`,
  },
})

export const SegmentVerticalHeadPanel = track((): ReactElement | null => {
  const editor = useEditor()

  const s = useStyles()

  const selectedShapes = editor.getSelectedShapes().filter(isFirePieceShape)
  const groups = [...new Set(selectedShapes.map(shape => shape.meta.group))]

  if (selectedShapes.length === 0 || groups.length > 1)
    return null

  const firePieceShapes = editor.getCurrentPageShapes()
    .filter(isFirePieceShape)
    .filter(shape => shape.meta.group === groups[0])

  const segmentVerticalHeadShapes = firePieceShapes
    .map(firePieceShape => getSegmentVerticalHeadShapeFromFirePieceShape({ editor, firePieceShape }))
    .filter((shape): shape is SegmentVerticalHeadShape => !!shape)

  return (
    <>
      <div className={s.heading}>
        <Text weight="semibold">
          {t('vertical-head.panel.title')}
        </Text>
      </div>
      <div><Divider /></div>
      <SegmentVerticalHeadLength
        key={groups[0]}
        editor={editor}
        firePieceShapes={firePieceShapes}
        segmentVerticalHeadShape={segmentVerticalHeadShapes.at(0)}
      />
      <SegmentVerticalHeadAttrs
        segmentVerticalHeadShapes={segmentVerticalHeadShapes}
      />
    </>
  )
})
