import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { type TLBindingCreate, shapeIdValidator } from 'tldraw'
import { fromServerPiece } from '../../annot/piece/server'
import { fromServerSegment } from '../../annot/segment/server'
import type { SegmentPartial } from '../../annot/segment/shape'
import type { AnnotShapePartial } from '../../annot/shape/shape'
import { ATTR_C_MODULE_DEFAULT } from '../../attr/field/c-module/value'
import type { AttrFirePipeValue } from '../../attr/field/fire-pipe/value'
import { ATTR_FIRE_PIPE_DEFAULT, ATTR_FIRE_PIPE_METADATA_KEY, parseAttrFirePipe } from '../../attr/field/fire-pipe/value'
import { fromServerShape } from '../../attr/field/shape/value'
import type { AttrRecord } from '../../attr/state/context'
import { VERTICAL_HEAD_BINDING_TYPE } from '../../editor/binding/vertical-head'
import type { InsulAttrRecord } from '../../insul/context'
import { fromInsulServer } from '../../insul/server'
import { ATTR_TREE_DEFAULT } from '../../util/attr/tree/value'
import type { AnnotationDetail, Pieces, Pipes } from '../../util/data/server'
import { server } from '../../util/data/server'
import { PAGE_ID } from '../state/id'

export type PageAnnots = {
  shapes: AnnotShapePartial[]
  attrs: AttrRecord
  insuls: InsulAttrRecord
  bindings: TLBindingCreate[]
}
function toFirePipe(annot: AnnotationDetail): AttrFirePipeValue {
  if (annot.dataType !== 'Pipes')
    return ATTR_FIRE_PIPE_DEFAULT

  const metadata = (annot.data as Pipes).metadata?.any ?? []
  const firePipe = metadata
    .find(i => i.key === ATTR_FIRE_PIPE_METADATA_KEY)
    ?.value ?? null
  if (firePipe === null)
    return ATTR_FIRE_PIPE_DEFAULT

  return parseAttrFirePipe(firePipe)
}
/**
 * Important: "shapes" and "attrs" must be transformed at the same time,
 * so that client-generated uuids are matched.
 *
 * @TODO: Ensure that we don't have client-generated uuids any more,
 * then consider extracting this into individual modules.
 * For example "annotation details" to "attr records" should happen
 * at "attr/provider" sintead.
 */
function toAnnots(prev: PageAnnots, annot: AnnotationDetail): PageAnnots {
  // @TODO: Move to "attr"
  prev.attrs[annot.data.uuid] = {
    cArea: annot.data.constructionArea?.at(0)?.toString() ?? ATTR_TREE_DEFAULT,
    equip: annot.equipmentClass,
    material1: annot.data.material?.at(0)?.toString() ?? ATTR_TREE_DEFAULT,
    material2: annot.data.material?.at(1)?.toString() ?? ATTR_TREE_DEFAULT,
    shape: fromServerShape(annot.data.shape ?? null),
    cModule: annot.moduleID ?? ATTR_C_MODULE_DEFAULT,
    type: annot.equipmentType,
    firePipe: toFirePipe(annot),
  }

  prev.insuls[annot.data.uuid] = fromInsulServer(annot.insulation ?? null)

  const group = annot.data
  switch (annot.dataType) {
    case 'Pipes': {
      const { lineSegments } = annot.data as Pipes
      const segments: SegmentPartial[] = []
      const bindings: TLBindingCreate[] = []
      lineSegments.forEach((segment) => {
        segments.push(fromServerSegment({ group, segment }))
        const serverBinding = segment.metadata?.find(({ key }) => key === VERTICAL_HEAD_BINDING_TYPE)
        if (serverBinding && serverBinding.value !== '') {
          bindings.push({
            type: VERTICAL_HEAD_BINDING_TYPE,
            fromId: shapeIdValidator.validate(segment.uuid),
            toId: shapeIdValidator.validate(serverBinding.value),
          })
        }
      })
      prev.shapes.push(...segments)
      prev.bindings.push(...bindings)
      break
    }
    case 'Pieces': {
      const pieces = (annot.data as Pieces)
        .pieces
        .map(server => fromServerPiece({ group, server, equip: annot.equipmentClass }))
      prev.shapes.push(...pieces)
      break
    }
    default:
      throw new Error(`Unknown data type: "${annot.dataType}"`)
  }
  prev.shapes.push()
  return prev
}

function createEmpty(): PageAnnots {
  return {
    shapes: [],
    attrs: {},
    insuls: {},
    bindings: [],
  }
}

/**
 * Important: Do not use this query directly, nor invalidate it.
 *
 * This is a snapshot of the annotations at the time of page load,
 * which is meant to be read only.
 * We do sync the annotations in real-time, but that's a passive sync,
 * meaning we don't wait for the sync result.
 * Moreover, we don't deal with "annotations" directly,
 * but convert them to "annots" (on canvas) and "attrs" (on panel).
 */
export function usePageAnnots(): UseQueryResult<PageAnnots> {
  return useQuery({
    queryKey: ['initial'],
    queryFn: async () => await server.listAnnotations(PAGE_ID),
    select: data => data.reduce(toAnnots, createEmpty()),
  })
}
