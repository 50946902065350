import { Box, DefaultColorStyle, shapeIdValidator } from 'tldraw'
import type { AnnotationData, PipeLineSegment, PipeLineSegmentVertical } from '../../../../../generated/server'
import { createSegmentVerticalPipeShape } from './create'
import type { SegmentVerticalPipePartial, SegmentVerticalPipeShape } from './shape'

export const SEGMENT_VERTICAL_PIPE = 'segment-vertical-pipe'

export function toServerSegmentVerticalPipe(
  client: SegmentVerticalPipeShape,
): PipeLineSegment {
  const box = new Box(client.x, client.y, client.props.w, client.props.h)

  const vertical: PipeLineSegmentVertical = {
    length: client.meta.mm,
    point: { x: box.center.x, y: box.center.y },
    direction: client.meta.direction,
  }

  const server: PipeLineSegment = {
    type: 'Vertical',
    uuid: client.id,
    inner: vertical,
    interactive: client.meta.interactive !== null
      ? client.meta.interactive
      : undefined,
    zoneID: client.meta.zoneID !== '' ? client.meta.zoneID : undefined,
    metadata: [{ key: 'type', value: SEGMENT_VERTICAL_PIPE }],
  }

  return server
}

export function fromServerSegmentVerticalPipe(props: {
  server: PipeLineSegmentVertical
  segment: PipeLineSegment
  group: AnnotationData
}): SegmentVerticalPipePartial {
  const { group, server, segment } = props

  const vertical = createSegmentVerticalPipeShape({
    center: server.point,
    direction: server.direction,
    mm: server.length,
    id: shapeIdValidator.validate(segment.uuid),
    color: DefaultColorStyle.validate(group.colorName),
    group: group.uuid,
    interactive: segment.interactive ?? null,
    zoneID: segment.zoneID ?? '',
  })

  return vertical
}
