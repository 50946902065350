import type { TLEventHandlers, TLInterruptEvent, TLShapeId, TLShapePartial } from 'tldraw'
import { Mat, StateNode, Vec, createShapeId, getIndexAbove, sortByIndex } from 'tldraw'
import type { PredictPolygonAreaShape } from '../shape'

const MINIMUM_DISTANCE_BETWEEN_HANDLES = 1

export class Pointing extends StateNode {
  static override id = 'pointing'

  shape = {} as PredictPolygonAreaShape

  markId: string | undefined

  isDragging = false

  override onEnter = (info: { shapeId?: TLShapeId, isDragging: boolean }) => {
    const shape = info.shapeId && this.editor.getShape<PredictPolygonAreaShape>(info.shapeId)
    // add new points to the current shapes
    // different from lines tool
    this.markId = undefined

    if (shape) {
      this.markId = `creating:${shape.id}`
      this.editor.mark(this.markId)
      this.shape = shape
      const handles = this.editor.getShapeHandles(this.shape)
      if (!handles)
        return

      const vertexHandles = handles.filter(handle => handle.type === 'vertex').sort(sortByIndex)

      // we don't use .at(-1) here because it somehow undefined
      const endHandle = vertexHandles[vertexHandles.length - 1]

      if (info.isDragging) {
        if (Object.values(this.shape.props.points).length === 3) {
          this.editor.deleteShape(this.shape.id)
          this.editor.setCurrentTool('select')
          return
        }
      }

      if (Object.values(this.shape.props.points).length > 2 && endHandle.x === this.shape.props.points.a1.x
        && endHandle.y === this.shape.props.points.a1.y) {
        this.editor.select(this.shape.id)
        this.editor.setCurrentTool('select')
        return
      }

      const shapePagePoint = Mat.applyToPoint(
        this.editor.getShapeParentTransform(this.shape)!,
        new Vec(this.shape.x, this.shape.y),
      )

      const nextPoint = Vec.Sub(this.editor.inputs.currentPagePoint, shapePagePoint)
      const points = structuredClone(this.shape.props.points)

      // Prevent switch to Select Tool if user click and slight drag at the first point
      if (Vec.Dist2(handles[0], handles[1]) < MINIMUM_DISTANCE_BETWEEN_HANDLES) {
        this.editor.select(this.shape.id)
        return
      }

      // Add a new point
      const nextIndex = getIndexAbove(endHandle.index)
      points[nextIndex] = {
        id: nextIndex,
        index: nextIndex,
        x: nextPoint.x,
        y: nextPoint.y,
      }

      this.editor.updateShapes([
        { id: this.shape.id, type: this.shape.type, props: { points } },
      ])
      this.editor.select(this.shape.id)
    }
    else {
      const id = createShapeId()
      this.markId = `creating:${id}`
      this.editor.mark(this.markId)
      this.editor.createShapes<PredictPolygonAreaShape>([{
        id,
        type: 'area',
        x: this.editor.inputs.currentPagePoint.x,
        y: this.editor.inputs.currentPagePoint.y,
        meta: {
          type: 'predict-polygon-area',
          id: crypto.randomUUID(),
          busy: false,
          params: null,
        },
        props: {
          color: 'blue',
        },
      }])

      this.editor.select(id)
      this.shape = this.editor.getShape(id)!
    }
  }

  override onPointerMove: TLEventHandlers['onPointerMove'] = () => {
    if (!this.shape)
      return

    const handles = this.editor.getShapeHandles(this.shape)
    if (!handles)
      throw new Error('No handles found')

    const lastHandle = structuredClone(last(handles)!)

    this.parent.transition('moving', {
      shape: this.shape,
      handle: { ...lastHandle },
    })
  }

  override onCancel: TLEventHandlers['onCancel'] = () => {
    this.cancel()
  }

  override onComplete: TLEventHandlers['onComplete'] = () => {
    this.complete()
  }

  override onRightClick: TLEventHandlers['onRightClick'] = () => {
    const firstPoint = this.shape.props.points.a1

    const shape = this.shape

    const handles = this.editor.getShapeHandles(shape)
    if (!handles)
      return

    const vertexHandles = handles.filter(handle => handle.type === 'vertex').sort(sortByIndex)

    const endHandle = vertexHandles[vertexHandles.length - 1]

    const nextHandle = {
      ...endHandle,
      x: firstPoint.x,
      y: firstPoint.y,
    }

    const util = this.editor.getShapeUtil(shape)

    const changes = util.onHandleDrag?.(shape, {
      handle: nextHandle,
      isPrecise: false,
      initial: shape,
    })

    const next: TLShapePartial<PredictPolygonAreaShape> = { id: shape.id, type: shape.type, ...changes }

    if (changes)
      this.editor.updateShapes([next])

    this.complete()
    this.editor.setCurrentTool('select')
  }

  override onInterrupt: TLInterruptEvent = () => {
    this.parent.transition('idle')
    if (this.markId)
      this.editor.bailToMark(this.markId)
    this.editor.snaps.clearIndicators()
  }

  complete() {
    this.parent.transition('idle', { shapeId: this.shape.id })
    this.editor.snaps.clearIndicators()
  }

  cancel() {
    const firstPoint = this.shape.props.points.a1

    const shape = this.shape

    const handles = this.editor.getShapeHandles(shape)
    if (!handles)
      return

    const vertexHandles = handles.filter(handle => handle.type === 'vertex').sort(sortByIndex)

    const endHandle = vertexHandles[vertexHandles.length - 1]

    const nextHandle = {
      ...endHandle,
      x: firstPoint.x,
      y: firstPoint.y,
    }

    const util = this.editor.getShapeUtil(shape)

    const changes = util.onHandleDrag?.(shape, {
      handle: nextHandle,
      isPrecise: false,
      initial: shape,
    })

    const next: TLShapePartial<PredictPolygonAreaShape> = { id: shape.id, type: shape.type, ...changes }

    if (changes)
      this.editor.updateShapes([next])

    this.complete()
    this.editor.setCurrentTool('select')
  }
}

export function last<T>(arr: readonly T[]): T | undefined {
  return arr[arr.length - 1]
}
