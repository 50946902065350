import type { RecordPropsType, TLBaseShape, TLShape } from 'tldraw'
import { DefaultColorStyle, DefaultSizeStyle, T } from 'tldraw'
import type { AIParams } from '../param/type'

const areaPointValidators = T.object({
  id: T.string,
  index: T.indexKey,
  x: T.number,
  y: T.number,
})

export const areaShapeProps = {
  color: DefaultColorStyle,
  size: DefaultSizeStyle,
  points: T.dict(T.string, areaPointValidators),
}

type PredictPolygonAreaShapeProps = RecordPropsType<typeof areaShapeProps>

export type PredictPolygonAreaShapeBase = TLBaseShape<'area', PredictPolygonAreaShapeProps>

export type AIParamsMeta = AIParams & { state: 'valid' | 'error' } | null

export type PredictPolygonAreaShapeMeta = {
  type: 'predict-polygon-area'
  id: string
  busy: boolean
  params: AIParamsMeta
}

export type PredictPolygonAreaShape =
  PredictPolygonAreaShapeBase
  & { meta: PredictPolygonAreaShapeMeta }

export function isPredictPolygonAreaShape(shape: TLShape): shape is PredictPolygonAreaShape {
  return shape.type === 'area' && shape.meta.type === 'predict-polygon-area'
}
