import { Fragment, type ReactElement } from 'react'
import type { SvgExportContext, TLGeoShape, TLShape } from 'tldraw'
import { GeoShapeUtil as TLGeoShapeUtil } from 'tldraw'
import { PieceBoxComponent } from '../../annot/piece/box/component'
import { isPieceBoxShape } from '../../annot/piece/box/shape'
import { PieceCheckComponent } from '../../annot/piece/check/component'
import { isPieceCheckShape } from '../../annot/piece/check/shape'
import { PieceCircleComponent } from '../../annot/piece/circle/component'
import { isPieceCircleShape } from '../../annot/piece/circle/shape'
import { SegmentExtensionComponent } from '../../annot/segment/extension/component'
import { isSegmentExtensionShape } from '../../annot/segment/extension/shape'
import { SegmentVerticalHeadComponent } from '../../annot/segment/vertical/head/component'
import { isSegmentVerticalHeadShape } from '../../annot/segment/vertical/head/shape'
import { SegmentVerticalPipeComponent } from '../../annot/segment/vertical/pipe/component'
import { isSegmentVerticalPipeShape } from '../../annot/segment/vertical/pipe/shape'
import { toSegmentVerticalPipeSvg } from '../../annot/segment/vertical/pipe/svg'
import { PredictAreaComponent } from '../../predict/area/component'
import { isPredictAreaShape } from '../../predict/area/shape'
import { PredictGroupSampleComponent } from '../../predict/group-sample/component'
import { isPredictGroupSampleShape } from '../../predict/group-sample/shape'

export class GeoShapeUtil extends TLGeoShapeUtil {
  override component(geo: TLGeoShape): ReactElement {
    const original = super.component(geo)

    if (isSegmentVerticalPipeShape(geo))
      return <SegmentVerticalPipeComponent vertical={geo} original={original} />

    if (isSegmentVerticalHeadShape(geo))
      return <SegmentVerticalHeadComponent vertical={geo} />

    if (isPredictAreaShape(geo))
      return <PredictAreaComponent area={geo} original={original} />

    if (isPredictGroupSampleShape(geo))
      return <PredictGroupSampleComponent original={original} shape={geo} />

    if (isPieceBoxShape(geo))
      return <PieceBoxComponent original={original} box={geo} />

    if (isPieceCheckShape(geo))
      return <PieceCheckComponent original={original} check={geo} />

    if (isPieceCircleShape(geo))
      return <PieceCircleComponent original={original} circle={geo} />

    if (isSegmentExtensionShape(geo))
      return <SegmentExtensionComponent original={original} extension={geo} />

    return original
  }

  override indicator(geo: TLGeoShape): ReactElement {
    const original = super.indicator(geo)

    // @TODO: Rotate this as well
    if (isSegmentVerticalPipeShape(geo) || isSegmentExtensionShape(geo))
      return <Fragment />

    return original
  }

  override toSvg(shape: TLGeoShape, ctx: SvgExportContext): ReactElement {
    const original = super.toSvg(shape, ctx)

    if (isSegmentVerticalPipeShape(shape))
      return toSegmentVerticalPipeSvg({ vertical: shape, original })

    return original
  }
}

export function isGeoShape(shape: TLShape): shape is TLGeoShape {
  return shape.type === GeoShapeUtil.type
}
