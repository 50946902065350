import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { usePage } from '../../../page/state/context'
import { toggleChild } from '../../../util/web/array'
import { isPieceShape } from '../../piece/shape'
import { isSegmentExtensionShape } from '../../segment/extension/shape'
import { getSegmentMmSize, isSegmentShape } from '../../segment/shape'
import { isSegmentVerticalPipeShape } from '../../segment/vertical/pipe/shape'
import type { AnnotShape } from '../../shape/shape'
import { AnnotButton } from '../base/button'

function getPrefix(shape: AnnotShape): string {
  if (isSegmentVerticalPipeShape(shape)) {
    switch (shape.meta.direction) {
      case 'up': return '↑'
      case 'down': return '↓'
    }
  }
  if (isSegmentExtensionShape(shape))
    return '↔︎'
  return ''
}

function getValue(shape: AnnotShape, options: {
  scale: number
}): number {
  const { scale } = options
  if (isSegmentShape(shape))
    return getSegmentMmSize(shape, { scale }) / 1000
  if (isPieceShape(shape))
    return 1
  throw new Error('Unknown annot shape')
}

export const AnnotShapeButton = track((props: {
  shape: AnnotShape
}): ReactElement => {
  const { shape } = props

  const { scale } = usePage().page
  const editor = useEditor()

  // It's safe to use selected ids (without "is annot shape" filter),
  // because we only check for existence of the shape ID inside this list.
  // We don't use this list directly to render anything.
  const selectedIds = editor.getSelectedShapeIds()

  return (
    <AnnotButton
      onClick={(event) => {
        const next = !event.shift
          ? [shape.id] // Single
          : toggleChild(selectedIds, shape.id) // Multiple
        editor.select(...next)
      }}
      selected={selectedIds.includes(shape.id)}
      color={shape.props.color}
      bold={false}
    >
      {getPrefix(shape)}
      {getValue(shape, { scale }).toFixed(2)}
    </AnnotButton>
  )
})
