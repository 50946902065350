import { z } from 'zod'

export const segmentLabelSchema = z.enum([
  'size',
  'shape',
  'vertical',
  'none',
])

export type SegmentLabel = z.infer<typeof segmentLabelSchema>
