import type { RecordPropsType, TLBaseShape, TLShape } from 'tldraw'
import { DefaultColorStyle, DefaultSizeStyle, T } from 'tldraw'

export const areaPointValidators = T.object({
  id: T.string,
  index: T.indexKey,
  x: T.number,
  y: T.number,
})

export const areaShapeProps = {
  color: DefaultColorStyle,
  size: DefaultSizeStyle,
  points: T.dict(T.string, areaPointValidators),
}

type PolygonShapeProps = RecordPropsType<typeof areaShapeProps>

export type PolygonShape = TLBaseShape<'area', PolygonShapeProps>

export function isPolygonShape(shape: TLShape): shape is PolygonShape {
  return shape.type === 'area'
}
