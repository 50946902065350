import type { AnnotationData, PipeLineSegment, PipeLineSegmentExtension, PipeLineSegmentFlat, PipeLineSegmentVertical } from '../../util/data/server'
import { fromServerSegmentExtension, toServerSegmentExtension } from './extension/server'
import { isSegmentExtensionShape } from './extension/shape'
import { fromServerSegmentFlat, toServerSegmentFlat } from './flat/server'
import { isSegmentFlatShape } from './flat/shape'
import type { SegmentShape } from './shape'
import { SEGMENT_VERTICAL_HEAD, fromServerSegmentVerticalHead, toServerSegmentVerticalHead } from './vertical/head/server'
import { isSegmentVerticalHeadShape } from './vertical/head/shape'
import { SEGMENT_VERTICAL_PIPE, fromServerSegmentVerticalPipe, toServerSegmentVerticalPipe } from './vertical/pipe/server'
import { isSegmentVerticalPipeShape } from './vertical/pipe/shape'

export function fromServerSegment(props: {
  group: AnnotationData
  segment: PipeLineSegment
}) {
  const { segment, group } = props
  switch (segment.type) {
    case 'Flat': {
      const server = segment.inner as PipeLineSegmentFlat
      return fromServerSegmentFlat({ group, segment, server })
    }
    case 'Vertical': {
      const server = segment.inner as PipeLineSegmentVertical
      const vertical = segment.metadata?.find(({ key }) => key === 'type')
      switch (vertical?.value) {
        case SEGMENT_VERTICAL_HEAD:
          return fromServerSegmentVerticalHead({ group, segment, server })
        case SEGMENT_VERTICAL_PIPE:
        default:
          return fromServerSegmentVerticalPipe({ group, segment, server })
      }
    }
    case 'Extension': {
      const server = segment.inner as PipeLineSegmentExtension
      return fromServerSegmentExtension({ group, segment, server })
    }
    case 'Extra':
      throw new Error('@TODO')
  }
}

export function toServerSegment(segment: SegmentShape): PipeLineSegment {
  if (isSegmentFlatShape(segment))
    return toServerSegmentFlat(segment)
  if (isSegmentVerticalPipeShape(segment))
    return toServerSegmentVerticalPipe(segment)
  if (isSegmentVerticalHeadShape(segment))
    return toServerSegmentVerticalHead(segment)
  if (isSegmentExtensionShape(segment))
    return toServerSegmentExtension(segment)
  throw new Error('Unknown segment type')
}
