import type { Polyline2d, TLDefaultSizeStyle } from 'tldraw'
import { Polygon2d, Vec, sortByIndex } from 'tldraw'
import type { PolygonShape } from '../../annot/polygon/shape'

export const STROKE_SIZES: Record<TLDefaultSizeStyle, number> = {
  s: 2,
  m: 3.5,
  l: 5,
  xl: 10,
}

export function linePointsToArray(shape: PolygonShape) {
  return Object.values(shape.props.points).sort(sortByIndex)
}

export function getGeometryForLineShape(shape: PolygonShape): Polyline2d {
  const points = linePointsToArray(shape).map(Vec.From)
  return new Polygon2d({ points, isFilled: true })
}

export function mapObjectMapValues<Key extends string, ValueBefore, ValueAfter>(
  object: { readonly [K in Key]: ValueBefore },
  mapper: (key: Key, value: ValueBefore) => ValueAfter,
): { [K in Key]: ValueAfter } {
  const result = {} as { [K in Key]: ValueAfter }
  for (const [key, value] of objectMapEntries(object)) {
    const newValue = mapper(key, value)
    result[key] = newValue
  }
  return result
}

export function objectMapEntries<Key extends string, Value>(object: {
  [K in Key]: Value
}): Array<[Key, Value]> {
  return Object.entries(object) as [Key, Value][]
}
