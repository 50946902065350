import type { MenuButtonProps } from '@fluentui/react-components'
import { Menu, MenuItemRadio, MenuList, MenuPopover, MenuTrigger, SplitButton } from '@fluentui/react-components'
import { SquareHintSparkles20Filled, SquareHintSparkles20Regular } from '@fluentui/react-icons'
import { type ReactElement, useState } from 'react'
import { track, useEditor } from 'tldraw'
import { useSetting } from '../../setting/setting'
import { t } from '../../util/intl/t'
import { predictAreaShapeSchema } from '../area/option'
import { PredictAreaTool } from '../area/tool'
import { PolygonAreaShapeTool } from './tool'

export const PredictSelectAreaShapeButton = track((props: {
  highlight: boolean
}): ReactElement => {
  const { highlight } = props

  const editor = useEditor()
  const { updateSetting, setting } = useSetting()

  const [shapeArea, setShapeArea] = useState<string | null>(null)

  const onClick = () => {
    editor.setCurrentTool(PredictAreaTool.id)
    setShapeArea(null)
  }

  const primaryActionButtonProps = {
    onClick,
  }

  return (
    <Menu
      positioning="below-end"
      checkedValues={{ value: shapeArea !== null ? [shapeArea] : [] }}
      onCheckedValueChange={(_event, data) => {
        const next = predictAreaShapeSchema.parse(data.checkedItems.at(0))
        setShapeArea(next)
        if (setting.predictModel === 'fire-pipe-diameter')
          updateSetting({ secondary: 'attr' })
        if (next === 'rectangle')
          editor.setCurrentTool(PredictAreaTool.id)
        else editor.setCurrentTool(PolygonAreaShapeTool.id)
      }}
    >
      <MenuTrigger disableButtonEnhancement>
        {(triggerProps: MenuButtonProps) => (
          <SplitButton
            menuButton={triggerProps}
            primaryActionButton={primaryActionButtonProps}
            appearance={highlight ? 'primary' : 'subtle'}
            icon={highlight ? <SquareHintSparkles20Filled /> : <SquareHintSparkles20Regular />}
          >
            {t('predict.area.select')}
          </SplitButton>
        )}
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          <MenuItemRadio name="value" value="polygon">
            {t('predict.fetch.select-polygon-area')}
          </MenuItemRadio>
        </MenuList>
      </MenuPopover>
    </Menu>
  )
})
