import { BotSparkle20Filled, BotSparkle20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { SelectTool, useEditor } from 'tldraw'
import { usePage } from '../../page/state/context'
import { useSetting } from '../../setting/setting'
import { MenuRadio } from '../../ui/menu'
import { t } from '../../util/intl/t'
import { groupByPredictSystem } from '../system/group'
import { PredictModelDrain } from './drain'
import { PredictModelDuct } from './duct'
import { PredictModelElectrical } from './electrical'
import { PredictModelFireHead } from './fire-head'
import { PredictModelFirePipe } from './fire-pipe'
import { PredictModelFirePipeDiameter } from './fire-pipe-diameter'
import type { PredictModelValue } from './option'
import { PREDICT_MODELS, predictModelValueSchema } from './option'

const OPTIONS = groupByPredictSystem(PREDICT_MODELS)

export const KYUDENKO_ORG_PROD = 'a4599b49-6812-4418-a791-0fb2dd127185'
export const KYUDENKO_ORG_QA = '10c15fcf-3631-493c-87d6-88784b9e2f1d'

function getModelList(orgID: string) {
  if (orgID === KYUDENKO_ORG_PROD || orgID === KYUDENKO_ORG_QA) {
    return groupByPredictSystem([
      PredictModelDuct,
      PredictModelDrain,
      PredictModelElectrical,
      PredictModelFireHead,
      PredictModelFirePipe,
      PredictModelFirePipeDiameter,
    ])
  }

  return OPTIONS
}

export function PredictModelSelect(props: {
  highlight: boolean
}): ReactElement {
  const { highlight } = props
  const editor = useEditor()
  const { setting, updateSetting } = useSetting()

  const page = usePage()
  const orgID = page.page.orgID
  const models = getModelList(orgID)

  return (
    <MenuRadio<PredictModelValue>
      value={setting.predictModel}
      setValue={(model) => {
        updateSetting({ predictModel: model })
        editor.setCurrentTool(SelectTool.id)
      }}
      displayFallback={t('predict.model.action')}
      //
      groups={models.map(group => ({
        label: group.system.label,
        options: group.models.map(model => ({
          label: model.label,
          value: model.value,
        })),
      }))}
      parse={predictModelValueSchema.parse}
      button={{
        appearance: highlight ? 'primary' : 'subtle',
        icon: highlight ? <BotSparkle20Filled /> : <BotSparkle20Regular />,
      }}
    />
  )
}
