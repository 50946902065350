import { DefaultColorStyle, shapeIdValidator } from 'tldraw'
import { getLineShapeEdgeAbsolute } from '../../../editor/shape/line'
import type { AnnotationData, PipeLineSegment, PipeLineSegmentFlat } from '../../../util/data/server'
import { getStrict } from '../../../util/web/primitive'
import { createSegmentFlatShape } from './create'
import type { SegmentFlatPartial, SegmentFlatShape } from './shape'

export function toServerSegmentFlat(
  client: SegmentFlatShape,
): PipeLineSegment {
  const { start, end } = getLineShapeEdgeAbsolute(client)
  const points = [start, end]

  const server: PipeLineSegment = {
    type: 'Flat',
    uuid: client.id,
    inner: { points },
    interactive: client.meta.interactive !== null
      ? client.meta.interactive
      : undefined,
    zoneID: client.meta.zoneID !== '' ? client.meta.zoneID : undefined,
  }

  return server
}

export function fromServerSegmentFlat(props: {
  server: PipeLineSegmentFlat
  segment: PipeLineSegment
  group: AnnotationData
}): SegmentFlatPartial {
  const { group, server, segment } = props

  const flat = createSegmentFlatShape({
    start: getStrict(server.points.at(0)),
    end: getStrict(server.points.at(1)),
    //
    id: shapeIdValidator.validate(segment.uuid),
    color: DefaultColorStyle.validate(group.colorName),
    group: group.uuid,
    interactive: segment.interactive ?? null,
    zoneID: segment.zoneID ?? '',
  })

  return flat
}
