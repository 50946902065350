import { makeStyles } from '@fluentui/react-components'
import type { ReactElement, ReactNode } from 'react'
import type { TLDefaultColorThemeColor } from 'tldraw'
import { EditorShapeStroke } from '../../../editor/shape/stroke'
import { useEditorTheme } from '../../../editor/util/theme'
import { useListZone } from '../server'
import { ZoneDropdownComponent } from './dropdown'
import type { ZoneShape } from './shape'

declare module 'react' {
  // eslint-disable-next-line ts/consistent-type-definitions
  interface CSSProperties {
    '--pac-fill-1'?: string
  }
}

const useStyles = makeStyles({
  container: {
    '& path[fill]:not([fill=\'none\'])': {
      fill: 'var(--pac-fill-1)',
      opacity: 0.25,
    },
  },
})

export function ZoneShapeComponent(props:
{
  shape: ZoneShape
  children: ReactNode
}): ReactElement | null {
  const { shape, children } = props

  const theme = useEditorTheme()
  const s = useStyles()

  const listZones = useListZone().data ?? []

  const color = listZones.find(zone => zone.id === shape.meta.zoneId)?.color ?? 'black'

  return (
    <div
      className={s.container}
      style={{
        '--pac-fill-1': (theme[color as keyof typeof theme] as TLDefaultColorThemeColor).solid,
      }}
    >
      <EditorShapeStroke>
        {children}
        <ZoneDropdownComponent shape={shape} />
      </EditorShapeStroke>
    </div>
  )
}
