import { CalendarPattern16Filled } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { AttrEquipField } from '../../../../attr/field/equip/field'
import { ATTR_EQUIP_VALUES } from '../../../../attr/field/equip/value'
import { AttrShapeField } from '../../../../attr/field/shape/field'
import type { AttrValue } from '../../../../attr/state/context'
import { useAttrs } from '../../../../attr/state/context'
import type { AttrSelection, AttrSelectionValue } from '../../../../util/attr/selection'
import { createAttrSelection, getAttrSelectionValue } from '../../../../util/attr/selection'
import { useAttrTreeDropdownValue } from '../../../../util/attr/tree/dropdown'
import { AttrTreeField } from '../../../../util/attr/tree/field'
import { t } from '../../../../util/intl/t'
import { getStrict } from '../../../../util/web/primitive'
import type { AnnotShape } from '../../../shape/shape'

export function SegmentVerticalHeadAttrs(props: {
  segmentVerticalHeadShapes: AnnotShape[]
}): ReactElement | null {
  const { segmentVerticalHeadShapes } = props

  const all = useAttrs()

  const attrs = segmentVerticalHeadShapes
    // We should handle missing attr flexibly here. There are short periods
    // during rendering where a new shape is created, along with its new group
    // ID, but the corresponding "setAttrs" is not settled yet.
    .map(shape => all.attrs[shape.meta.group] ?? null)
    .filter((attr): attr is AttrValue => attr !== null)

  const create = <Value extends AttrSelectionValue>(
    getter: (prev: AttrValue) => Value,
  ): AttrSelection<Value> => {
    return createAttrSelection(attrs.map(getter))
  }

  const get = getAttrSelectionValue

  const equip = create(a => a.equip)
  const equipValue = get(equip, -1)

  const material1 = create(a => a.material1)
  const material1Value = useAttrTreeDropdownValue({
    mixedText: t('attr.material-1.mixed'),
    value: material1,
    tree: { equip: equipValue, type: 'material', node: 0 },
  })

  const material2 = create(a => a.material2)
  const material2Value = useAttrTreeDropdownValue({
    mixedText: t('attr.material-2.mixed'),
    value: material2,
    tree: { equip: equipValue, type: 'material', node: Number.parseInt(get(material1, '-1')) },
  })

  const set = (update: Partial<AttrValue>): void => {
    const nextAll = segmentVerticalHeadShapes.reduce((acc, shape) => {
      // Technically, we could use "attrs" instead of "shapes", and always have
      // a defined "attr". However, using "shapes" here is more reliable because
      // "attrs" may not contain some groups, which is expected during the
      // rendering but should be an error in user actions like here.
      const attr = getStrict(all.attrs[shape.meta.group])
      acc[shape.meta.group] = { ...attr, ...update }
      return acc
    }, { ...all.attrs })
    all.setAttrs(nextAll)
  }

  if (attrs.length === 0)
    return null

  return (
    <>
      <AttrEquipField
        annotType="segment"
        equip={equip}
        setEquip={equip => set({ equip })}
      />
      {material1Value && (
        <AttrTreeField
          name="material1"
          value={material1Value}
          setValue={set}
          resetChildValue={['material2']}
          icon={icon => <CalendarPattern16Filled className={icon.className} />}
          label={t('attr.field.material-1')}
        />
      )}
      {material2Value && (
        <AttrTreeField
          name="material2"
          value={material2Value}
          setValue={set}
          icon={icon => <CalendarPattern16Filled className={icon.className} />}
          label={t('attr.field.material-2')}
        />
      )}
      <AttrShapeField
        equip={ATTR_EQUIP_VALUES.FIRE_PROTECTION_PIPE}
        nodes={[0, -1, -1]}
        shape={create(a => a.shape)}
        setShape={shape => set({ shape })}
      />
    </>
  )
}
