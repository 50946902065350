import { Text, makeStyles, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { useEditorTheme } from '../../../../editor/util/theme'
import { EDITOR_ZOOM_VARIABLE } from '../../../../editor/util/zoom'
import { useSetting } from '../../../../setting/setting'
import type { SegmentVerticalHeadShape } from './shape'

const useStyles = makeStyles({
  label: {
    transform: 'translateX(-50%)',
    // "width" is extrinsic by default
    width: 'min-content',
    // "height" is intrinsic by default but better to be explicit
    height: 'min-content',
    // There is also an "opacity" set at the shape itself already
    backgroundColor: tokens.colorNeutralBackground1,
    padding: '0.25em',
    marginTop: '0.25em',
    lineHeight: '1',
    fontWeight: 'bold',
  },
})

export function SegmentVerticalHeadComponent(props: {
  vertical: SegmentVerticalHeadShape
}): ReactElement {
  const { vertical } = props

  const s = useStyles()

  const color = useEditorTheme()[vertical.props.color]

  const { segment, segmentSize } = useSetting().setting

  if (segment !== 'vertical')
    return <></>

  const size = segmentSize.type === 'fixed' ? segmentSize.px : undefined

  return (
    <Text
      className={s.label}
      block // For positioning to work
      style={{
        color: color.solid,
        fontSize: size
          ? `${size}px`
          : `calc(16px / var(${EDITOR_ZOOM_VARIABLE}))`,
      }}
    >
      {(vertical.meta.mm / 1_000).toFixed(1)}
    </Text>
  )
}
