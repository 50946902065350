import { makeStyles } from '@fluentui/react-components'
import type { ReactElement, ReactNode } from 'react'
import { SVGContainer } from 'tldraw'
import type { PolygonShape } from '../../annot/polygon/shape'
import { EditorShapeStroke } from '../../editor/shape/stroke'
import { useEditorTheme } from '../../editor/util/theme'
import { getGeometryForLineShape } from './util'

declare module 'react' {
  // eslint-disable-next-line ts/consistent-type-definitions
  interface CSSProperties {
    '--pac-fill-1'?: string
    '--pac-fill-2'?: string
  }
}

const useStyles = makeStyles({
  container: {
    '& path[fill]:not([fill=\'none\'])': {
      fill: 'var(--pac-fill-1)',
      opacity: 0.25,
    },
  },
  busy: {
    top: 0,
    left: 0,
    inset: 0,
    animation: `0.8s alternate ease-in-out infinite fade-in`,
  },
})

export function PolygonComponent(props: {
  children: ReactNode
  shape: PolygonShape
}): ReactElement {
  const { children, shape } = props
  const theme = useEditorTheme()
  const s = useStyles()

  const spline = getGeometryForLineShape(shape)
  const { color } = shape.props
  const outline = spline.points
  const pathData = `M${outline[0]}L${outline.slice(1)}`

  return (
    <div
      className={s.container}
      style={{
        '--pac-fill-1': theme.blue.solid,
        '--pac-fill-2': theme['light-blue'].solid,
      }}
    >
      <EditorShapeStroke>
        {children}
      </EditorShapeStroke>
      {shape.meta.busy && (
        <SVGContainer id={shape.id} className={s.busy}>
          <path fill={theme[color].semi} d={pathData} />
        </SVGContainer>
      )}
    </div>
  )
}
