import { DefaultColorStyle, shapeIdValidator } from 'tldraw'
import type { AnnotationData, PipeLineSegment, PipeLineSegmentVertical } from '../../../../../generated/server'
import { VERTICAL_HEAD_BINDING_TYPE } from '../../../../editor/binding/vertical-head'
import { createSegmentVerticalHeadShape } from './create'
import type { SegmentVerticalHeadPartial, SegmentVerticalHeadShape } from './shape'

export const SEGMENT_VERTICAL_HEAD = 'segment-vertical-head'

export function toServerSegmentVerticalHead(
  client: SegmentVerticalHeadShape,
): PipeLineSegment {
  const vertical: PipeLineSegmentVertical = {
    length: client.meta.mm,
    point: { x: client.x, y: client.y },
    direction: client.meta.direction,
  }

  const server: PipeLineSegment = {
    type: 'Vertical',
    uuid: client.id,
    inner: vertical,
    interactive: client.meta.interactive !== null
      ? client.meta.interactive
      : undefined,
    metadata: [
      { key: 'type', value: SEGMENT_VERTICAL_HEAD },
      { key: VERTICAL_HEAD_BINDING_TYPE, value: client.meta.firePiece },
    ],
    zoneID: client.meta.zoneID !== '' ? client.meta.zoneID : undefined,
  }

  return server
}

export function fromServerSegmentVerticalHead(props: {
  server: PipeLineSegmentVertical
  segment: PipeLineSegment
  group: AnnotationData
}): SegmentVerticalHeadPartial {
  const { group, server, segment } = props

  const firePiece = segment.metadata?.find(({ key }) => key === VERTICAL_HEAD_BINDING_TYPE)
  const vertical = createSegmentVerticalHeadShape({
    x: server.point.x,
    y: server.point.y,
    mm: server.length,
    id: shapeIdValidator.validate(segment.uuid),
    color: DefaultColorStyle.validate(group.colorName),
    group: group.uuid,
    firePiece: firePiece?.value ?? '',
    zoneID: segment.zoneID ?? '',
  })

  return vertical
}
