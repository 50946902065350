import type { TLStateNodeConstructor } from 'tldraw'
import { StateNode } from 'tldraw'
import { Idle } from './tool-state/idle'
import { Moving } from './tool-state/moving'
import { Pointing } from './tool-state/pointing'

export class PolygonAreaShapeTool extends StateNode {
  static override id = 'polygon-area'
  static override initial = 'idle'
  static override children = (): TLStateNodeConstructor[] => [Idle, Pointing, Moving]
  override shapeType = 'polygon-area'
}

// area tool state
// idle -> pointing -> moving
// enter -> shape already avail -> move to pointing to create shape
