import type { BoxLike, Editor, TLShape, VecLike } from 'tldraw'
import { Box, Vec, getIndices, intersectLineSegmentPolygon, pointInPolygon, sortByIndex } from 'tldraw'
import { makeSegment } from '../../annot/cut/tool'
import type { SegmentFlatShape } from '../../annot/segment/flat/shape'
import { isSegmentFlatShape } from '../../annot/segment/flat/shape'
import type { AttrRecord } from '../../attr/state/context'
import { isGeoShape } from '../../editor/shape/geo'
import { getLineShapeEdgeAbsolute } from '../../editor/shape/line'
import type { SetState } from '../../util/react/state'
import { getCanvasBlob } from '../../util/web/canvas'
import { getStrict } from '../../util/web/primitive'
import type { PredictPolygonAreaShape } from '../polygon-area/shape'

/**
 * Manual type because of OpenAPI's limitation.
 * See server's "predictEquipmentsByAi".
 *
 * Values are scaled. "scale" is attached for convenience.
 * It should always be the same as "resolution" from page rendering.
 */
type Source = {
  scale: number
  x: number
  y: number
  w: number
  h: number
}

type Transform = {
  vec: (prev: VecLike) => Vec
  box: (prev: BoxLike) => Box
  line: (prev: [VecLike, VecLike]) => [Vec, Vec]
}

export type { Transform as PredictFetchCropTransform }

function makeTransform(vec: Transform['vec']): Transform {
  return {
    vec,
    box: (prev) => {
      const points = Box.From(prev).corners.map(vec)
      return Box.FromPoints(points)
    },
    line: (prev) => {
      const [start, end] = prev
      return [vec(start), vec(end)]
    },
  }
}

export type PredictFetchCrop = {
  blob: Blob
  source: Source
  globalise: Transform
  localise: Transform
  group: string | null
}

async function cropPredictFetch(props: {
  shape: TLShape
  editor: Editor
  pdf: HTMLCanvasElement
  resolution: number
}): Promise<PredictFetchCrop> {
  const { editor, shape, pdf, resolution } = props

  const box = editor.getShapePageBounds(shape)

  // Likely coding error, e.g., shape is not added to the page
  if (!box)
    throw new Error('No bounds found')

  const [S, a] = [resolution, box]
  const [x, y, w, h] = [a.x * S, a.y * S, a.w * S, a.h * S]
  const rect = new DOMRect(x, y, w, h)

  return {
    blob: await getCanvasBlob({ canvas: pdf, rect }),
    globalise: makeTransform((prev) => {
      const vec = Vec.From(prev)
      return vec.div(resolution).add(box)
    }),
    localise: makeTransform((prev) => {
      const vec = Vec.From(prev)
      return vec.sub(box).mul(resolution)
    }),
    source: { scale: S, x, y, w, h },
    group: shape.meta.group_template?.toString() ?? null,
  }
}

export function makeCropPredictFetch(props: {
  editor: Editor
  pdf: HTMLCanvasElement
  resolution: number
}): (shape: TLShape) => Promise<PredictFetchCrop> {
  const { editor, pdf, resolution } = props
  return shape => cropPredictFetch({ shape, editor, pdf, resolution })
}

export function getPredictFetchCropShapes(props: {
  editor: Editor
  area: TLShape
}): TLShape[] {
  const { editor, area } = props

  const container = editor.getShapePageBounds(area)
  if (!container)
    throw new Error('No bounds found')

  return editor.getCurrentPageShapes().filter((shape) => {
    const box = editor.getShapePageBounds(shape)
    if (!box)
      throw new Error('No bounds found')
    return container.contains(box)
  })
}

function linePointsToArray(shape: PredictPolygonAreaShape) {
  return Object.values(shape.props.points).sort(sortByIndex)
}

export const SEGMENT_FLAT_INDICES = (() => {
  const indices = getIndices(2)
  return {
    start: getStrict(indices.at(0)),
    end: getStrict(indices.at(1)),
  } as const
})()

export function getPredictPolygonFetchCropShapes(props: {
  editor: Editor
  area: PredictPolygonAreaShape
  setAttrs: SetState<AttrRecord>
}): TLShape[] {
  const { editor, area, setAttrs } = props

  const shapes: TLShape[] = []

  const points = linePointsToArray(area).map(Vec.From)
  const transform = points.map(point => ({ x: point.x + area.x, y: point.y + area.y }))

  editor.getCurrentPageShapes().forEach((shape) => {
    if (isSegmentFlatShape(shape)) {
      const { start, end } = getLineShapeEdgeAbsolute(shape)

      const intersections = intersectLineSegmentPolygon(start, end, transform)
      if (intersections) {
        intersections.sort((a, b) => {
          if (a.x === b.x)
            return a.y - b.y

          return a.x - b.x
        })

        const segments = cutSegmentRegression({ shape, intersections })

        setAttrs(prev => cloneAttrs({ prev, changes: segments }))

        editor.createShapes(segments)
        editor.deleteShapes([shape])

        segments.forEach((add) => {
          const edge = getLineShapeEdgeAbsolute(add).vertices
          const [start, end] = edge

          if (pointInPolygon(start, transform) && pointInPolygon(end, transform))
            shapes.push(add)
        })
      }
      // flat shape completely inside polygon
      else {
        const edge = getLineShapeEdgeAbsolute(shape).vertices

        const [start, end] = edge
        if (pointInPolygon(start, transform) && pointInPolygon(end, transform))
          shapes.push(shape)
      }
    }
    else {
      if (isGeoShape(shape)) {
        const center = new Box(shape.x, shape.y, shape.props.w, shape.props.h).center
        const inside = pointInPolygon(center, transform)
        if (inside)
          shapes.push(shape)
      }
    }
  })

  return shapes
}

export function cloneAttrs(props: {
  prev: AttrRecord
  changes: SegmentFlatShape[]
}): AttrRecord {
  const { prev, changes } = props

  const next = { ...prev }

  changes.forEach((change) => {
    const original = getStrict(prev[change.meta.group])
    // Technically we only need to create new attribute for the smaller new
    // segment, because the bigger one keeps the original attribute. However,
    // in practice the code below works for both cases so we can skip the check.
    // change.forEach((add) => {
    next[change.meta.group] = { ...original }
    // })
  })

  return next
}

export function cutSegmentRegression(props: {
  shape: SegmentFlatShape
  intersections: VecLike[]
}): SegmentFlatShape[] {
  const { shape, intersections } = props

  if (intersections.length === 0)
    return [shape]

  // Clone the first intersection to avoid mutating the original
  const firstIntersection = intersections[0]
  const vec = firstIntersection as Vec
  vec.sub(shape)

  const { start, end } = SEGMENT_FLAT_INDICES

  // Create new segments
  const segments = [
    makeSegment({ original: shape, vec, index: end }),
    makeSegment({ original: shape, vec, index: start }),
  ]
  // Recursively process remaining intersections
  const remainingIntersections = intersections.slice(1)
  return [
    segments[0],
    ...cutSegmentRegression({ shape: segments[1], intersections: remainingIntersections }),
  ]
}
