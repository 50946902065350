import type { ReactElement } from 'react'
import { Box } from 'tldraw'
import { SEGMENT_VERTICAL_PIPE_SIZE } from './create'
import type { SegmentVerticalPipeShape } from './shape'

export function toSegmentVerticalPipeSvg(props: {
  vertical: SegmentVerticalPipeShape
  original: ReactElement
}): ReactElement {
  const { original, vertical } = props

  if (vertical.meta.direction === 'down') {
    const s = SEGMENT_VERTICAL_PIPE_SIZE
    const box = new Box(0, 0, s, s)
    const { x, y } = box.center

    return (
      <g transform={`rotate(180 ${x} ${y})`}>
        {original}
      </g>
    )
  }

  return original
}
