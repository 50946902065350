import { Card, ProgressBar, Skeleton, SkeletonItem, Text, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { t } from '../util/intl/t'
import { AppFrame } from './frame'
import { AppToolbarLogo } from './toolbar/logo'

const useStyles = makeStyles({
  toolbar: {
    height: '48px',
    backgroundColor: tokens.colorNeutralBackground2,
  },
  tab: {
    height: '32px',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    ...(shorthands).gap(tokens.spacingHorizontalS),
  },
  text: {
    width: '100px',
  },
  card: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: tokens.colorNeutralBackground2,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    ...(shorthands).gap(tokens.spacingVerticalXS),
    height: '100%',
  },
  progress: {
    minWidth: '60%',
    backgroundColor: tokens.colorNeutralBackground1,
  },
  progressHelp: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: tokens.spacingVerticalS,
  },
})

export function AppPending(props: { progress: number }): ReactElement {
  const s = useStyles()

  const card = (
    <Card
      className={s.card}
      appearance="filled"
    />
  )

  const text = (
    <Skeleton className={s.text}>
      <SkeletonItem />
    </Skeleton>
  )

  const side = (
    <div className={s.container}>
      <div className={s.tab}>
        {text}
      </div>
      {card}
    </div>
  )

  const editor = (
    <div className={s.container}>
      <div className={s.tab}>
        {text}
      </div>
      <Card className={s.card} appearance="filled">
        <Card className={s.progress}>
          <div className={s.progressHelp}>
            <Text>{t('app.pending.help')}</Text>
            <Text>{`${Math.round(props.progress * 100)}%`}</Text>
          </div>
          <ProgressBar thickness="large" value={props.progress} />
        </Card>
      </Card>
    </div>
  )

  const toolbar = (
    <div className={s.container}>
      <div className={s.tab}>
        <AppToolbarLogo />
        {text}
      </div>
      <Card className={s.toolbar} appearance="filled" />
    </div>
  )

  return (
    <AppFrame
      toolbar={toolbar}
      primary={side}
      editor={editor}
      secondary={side}
    />
  )
}
