import { Dropdown, Option, Text, makeStyles, tokens } from '@fluentui/react-components'
import { Toolbox16Filled } from '@fluentui/react-icons'
import type { AnnotType } from '../../../annot/shape/shape'
import { Field } from '../../../ui/field'
import type { AttrSelection } from '../../../util/attr/selection'
import { getAttrSelectionValue, getAttrSelectionValueWhen } from '../../../util/attr/selection'
import { locale } from '../../../util/intl/locale/type'
import { t } from '../../../util/intl/t'
import { getStrict } from '../../../util/web/primitive'
import type { AttrEquipOption, AttrEquipValue } from './value'
import { ATTR_EQUIP_ELECTRICAL, ATTR_EQUIP_FIRE_PIECES, useAttrEquipOptions } from './value'

type Filter = (equip: AttrEquipOption) => boolean

function byAnnotType(annotType: AnnotType | null): Filter {
  switch (annotType) {
    case 'piece':
      return equip => equip.annotationDataType === 'Pieces'
    case 'segment':
      return equip => equip.annotationDataType === 'Pipes'
    case null:
      return () => false
  }
}

function groupEquipOptions(options: AttrEquipOption[], equip: AttrSelection<AttrEquipValue>): AttrEquipOption[] {
  const isFireHeadEquip = ATTR_EQUIP_FIRE_PIECES.includes(getAttrSelectionValue(equip, -1))
  const isElectricalEquip = ATTR_EQUIP_ELECTRICAL.includes(getAttrSelectionValue(equip, -1))

  return options.filter((option) => {
    const rest = ![...ATTR_EQUIP_FIRE_PIECES, ATTR_EQUIP_ELECTRICAL].includes(option.equipmentClass)
    if (isFireHeadEquip) {
      const fireEquips = ATTR_EQUIP_FIRE_PIECES.includes(option.equipmentClass)
      return fireEquips
    }
    if (isElectricalEquip)
      return ATTR_EQUIP_ELECTRICAL.includes(option.equipmentClass)

    return rest
  })
}

const useStyles = makeStyles({
  help: {
    color: tokens.colorNeutralForeground4,
  },
})

export function AttrEquipField(props: {
  annotType: AnnotType | null
  equip: AttrSelection<AttrEquipValue>
  setEquip: (equip: AttrEquipValue) => void
  withHelpText?: boolean
}): JSX.Element {
  const { equip: selection, setEquip: setValue, annotType, withHelpText } = props

  const s = useStyles()

  const language = locale === 'vi' ? 'ja' : locale
  const options = useAttrEquipOptions()
    .filter(e => e.locale === language)
    .filter(byAnnotType(annotType))

  const groupOptions = groupEquipOptions(options, selection)

  return (
    <>
      <Field
        icon={icon => <Toolbox16Filled className={icon.className} />}
        label={t('attr.field.equip')}
      >
        {control => (
          <Dropdown
            value={getAttrSelectionValueWhen(selection, {
              mixed: t('attr.equip.mixed'),
              same: value => options
                .find(option => option.equipmentClass === value)
                ?.localed ?? '',
            })}
            selectedOptions={getAttrSelectionValueWhen(selection, {
              mixed: [],
              same: value => [value.toString()],
            })}
            onOptionSelect={(_event, data): void => {
              const text = getStrict(data.optionValue)
              setValue(Number.parseInt(text))
            }}
            className={control.className}
            appearance={control.appearance}
          >
            {groupOptions.map(option => (
              <Option
                key={`${option.equipmentClass}.${option.locale}`}
                value={option.equipmentClass.toString()}
              >
                {option.localed}
              </Option>
            ))}
          </Dropdown>
        )}
      </Field>
      {withHelpText && <Text className={s.help} size={200}>{t('attr.equip.help')}</Text>}
    </>
  )
}
