import { SpinButton } from '@fluentui/react-components'
import { ArrowBidirectionalUpDown16Filled } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useState } from 'react'
import type { Editor } from 'tldraw'
import { createShapeId } from 'tldraw'
import { useAttrs } from '../../../../attr/state/context'
import { useSetting } from '../../../../setting/setting'
import { Field } from '../../../../ui/field'
import { onSpinButtonChange } from '../../../../ui/spin-button'
import { t } from '../../../../util/intl/t'
import type { PieceBoxShape } from '../../../piece/box/shape'
import { createAndBindSegmentVerticalHeadShapes } from './create'
import type { SegmentVerticalHeadShape } from './shape'
import {
  createSegmentVerticalHeadAttr,
  getAttrsFromFirePieceAttrs,
  getSegmentVerticalHeadShapeFromFirePieceShape,
} from './shape'

export function SegmentVerticalHeadLength(props: {
  editor: Editor
  firePieceShapes: PieceBoxShape[]
  segmentVerticalHeadShape?: SegmentVerticalHeadShape
}): ReactElement {
  const { editor, firePieceShapes, segmentVerticalHeadShape } = props

  const { updateSetting } = useSetting()
  const { attrs, setAttrs } = useAttrs()

  const [value, setValue] = useState<number>(segmentVerticalHeadShape?.meta.mm ?? 0)

  const onValueChange = (mm: number) => {
    editor.mark()
    setValue(mm)
    updateSetting({ segment: 'vertical' })

    if (segmentVerticalHeadShape) {
      const segmentVerticalHeadShapes: SegmentVerticalHeadShape[] = []
      const newFirePieceShapes: PieceBoxShape[] = []
      firePieceShapes.forEach((firePieceShape) => {
        const shape = getSegmentVerticalHeadShapeFromFirePieceShape({
          editor,
          firePieceShape,
        })
        if (!shape)
          newFirePieceShapes.push(firePieceShape)
        else
          segmentVerticalHeadShapes.push(shape)
      })

      // Create segment vertical head shapes for new fire pieces
      createAndBindSegmentVerticalHeadShapes({
        editor,
        firePieceShapes: newFirePieceShapes,
        group: segmentVerticalHeadShape.meta.group,
        mm,
      })

      // Update existing segment vertical head shapes
      editor.updateShapes(segmentVerticalHeadShapes.map(shape => ({
        ...shape,
        isLocked: false,
      })))
      editor.updateShapes(segmentVerticalHeadShapes.map(shape => ({
        ...shape,
        isLocked: true,
        meta: { ...shape.meta, mm },
      })))

      return
    }

    const group = createShapeId()
    createAndBindSegmentVerticalHeadShapes({ editor, firePieceShapes, group, mm })

    const fireEquip = attrs[firePieceShapes[0].meta.group]
    setAttrs({
      ...attrs,
      [group]: {
        ...createSegmentVerticalHeadAttr(),
        ...getAttrsFromFirePieceAttrs(fireEquip),
      },
    })
  }

  return (
    <Field
      label={t('vertical-head.panel.input')}
      icon={icon => <ArrowBidirectionalUpDown16Filled className={icon.className} />}
    >
      <SpinButton
        value={value}
        onChange={onSpinButtonChange(onValueChange)}
        appearance="filled-darker"
        min={0}
        step={1}
      />
    </Field>
  )
}
