import type { Editor, TLDefaultColorStyle, TLShapeId } from 'tldraw'
import { createShapeId, shapeIdValidator } from 'tldraw'
import { VERTICAL_HEAD_BINDING_TYPE } from '../../../../editor/binding/vertical-head'
import type { PieceBoxShape } from '../../../piece/box/shape'
import { ANNOT_SHAPE_BASE } from '../../../shape/base'
import { randomAnnotShapeColor } from '../../../shape/color'
import type { SegmentVerticalHeadPartial } from './shape'

const SEGMENT_VERTICAL_HEAD_SIZE = 3

export function createSegmentVerticalHeadShape(props: {
  x: number
  y: number
  //
  id: TLShapeId | null
  color: TLDefaultColorStyle | null
  group: string | null
  mm: number
  firePiece: string
  zoneID: string
}): SegmentVerticalHeadPartial {
  const { x, y, id, color, group, mm, firePiece, zoneID } = props

  const vertical: SegmentVerticalHeadPartial = {
    ...(ANNOT_SHAPE_BASE),
    type: 'geo',
    id: id ?? createShapeId(),
    x,
    y,
    props: {
      size: 's',
      color: color ?? randomAnnotShapeColor(),
      w: SEGMENT_VERTICAL_HEAD_SIZE,
      h: SEGMENT_VERTICAL_HEAD_SIZE,
    },
    meta: {
      type: 'annot',
      group: group ?? crypto.randomUUID(),
      interactive: 'ByManual',
      annot: 'segment',
      segment: 'vertical',
      vertical: 'head',
      direction: 'down',
      mm,
      firePiece,
      zoneID,
    },
    // Segment vertical head should be updated when fire piece updates
    isLocked: true,
  }

  return vertical
}

export function createAndBindSegmentVerticalHeadShapes(props: {
  editor: Editor
  firePieceShapes: PieceBoxShape[]
  group: string
  mm: number
}) {
  const { editor, firePieceShapes, group, mm } = props

  const shapes = firePieceShapes.map((firePieceShape) => {
    return createSegmentVerticalHeadShape({
      id: null,
      // The center of the vertical length is the bottom center of the fire head
      x: firePieceShape.x + firePieceShape.props.w / 2,
      y: firePieceShape.y + firePieceShape.props.h,
      color: firePieceShape.props.color,
      group,
      mm,
      firePiece: firePieceShape.id,
      zoneID: '',
    })
  })
  editor.createShapes(shapes)

  const bindings = shapes.map((shape) => {
    return {
      fromId: shape.id,
      toId: shapeIdValidator.validate(shape.meta.firePiece),
      type: VERTICAL_HEAD_BINDING_TYPE,
    }
  })
  editor.createBindings(bindings)
}
