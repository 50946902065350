import { makeStyles } from '@fluentui/react-components'
import type { ReactElement, ReactNode } from 'react'
import { SVGContainer, useEditor } from 'tldraw'
import { EditorShapeStroke } from '../../editor/shape/stroke'
import { useEditorTheme } from '../../editor/util/theme'
import type { PredictAreaShape } from './shape'

// https://github.com/frenic/csstype?tab=readme-ov-file#what-should-i-do-when-i-get-type-errors
declare module 'react' {
  // eslint-disable-next-line ts/consistent-type-definitions
  interface CSSProperties {
    '--pac-fill-1'?: string
    '--pac-fill-2'?: string
  }
}

const useStyles = makeStyles({
  container: {
    '& svg path[fill]:not([fill=\'none\'])': {
      opacity: 0.25,
      // When the geo fill is "solid",
      // tldraw actually uses the "semi" colour to fill it,
      // which is hard to see with opacity.
      // This uses the "solid" colour instead, like the colour used at stroke.
      fill: 'var(--pac-fill-1)',
    },
  },
  busy: {
    top: 0,
    left: 0,
    inset: 0,
    animation: `0.8s alternate ease-in-out infinite fade-in`,
  },
})

export function PredictAreaComponent(props: {
  original: ReactNode
  area: PredictAreaShape
}): ReactElement {
  const { original, area } = props

  const s = useStyles()
  const theme = useEditorTheme()
  const editor = useEditor()

  const { color } = area.props
  const shape = editor.getShapeGeometry(area)
  const outline = shape.getVertices()
  const pathData = `M${outline[0]}L${outline.slice(1)}`

  return (
    <div
      className={s.container}
      style={{
        '--pac-fill-1': theme.blue.solid,
        '--pac-fill-2': theme['light-blue'].solid,
      }}
    >
      <EditorShapeStroke>
        {original}
      </EditorShapeStroke>
      {area.meta.busy && (
        <SVGContainer id={area.id} className={s.busy}>
          <path fill={theme[color].semi} d={pathData} />
        </SVGContainer>
      )}
    </div>
  )
}
