import { ToolbarButton } from '@fluentui/react-components'
import { Delete20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Box, Vec, pointInPolygon, track, useEditor } from 'tldraw'
import { Key } from 'ts-key-enum'
import { getLineShapeEdgeAbsolute } from '../../editor/shape/line'
import { linePointsToArray } from '../../predict/polygon-area/util'
import { useToast } from '../../ui/toast'
import { ToolbarTooltip } from '../../ui/toolbar/tooltip'
import { t } from '../../util/intl/t'
import { isZoneShape } from '../polygon/zone/shape'
import { isSegmentFlatShape } from '../segment/flat/shape'
import { isAnnotShape } from '../shape/shape'

export const AnnotToolbarDelete = track((): ReactElement => {
  const { toast } = useToast()
  const editor = useEditor()

  const fn = async () => {
    // Heads up: This intentionally deletes all types of shapes.
    // It includes not only annotations but also predict shapes.
    // @TODO: Move this to somewhere more generic?
    const shapes = editor.getSelectedShapes()

    const annotShapes = editor.getCurrentPageShapes().filter(isAnnotShape)
    if (shapes.length === 0)
      return toast(t('annot.toolbar.delete-none'))
    editor.mark()

    shapes.forEach((shape) => {
      // Unlink annot shapes inside zone
      if (isZoneShape(shape)) {
        annotShapes.forEach((annot) => {
          const points = linePointsToArray(shape).map(Vec.From)
          const transform = points.map(point => ({ x: point.x + shape.x, y: point.y + shape.y }))

          if (isSegmentFlatShape(annot)) {
            const edge = getLineShapeEdgeAbsolute(annot).vertices
            const [start, end] = edge

            if (pointInPolygon(start, transform) && pointInPolygon(end, transform)
              && annot.meta.zoneID === shape.meta.zoneId)
              editor.updateShape({ ...annot, meta: { ...annot.meta, zoneID: '' } })
          }
          else {
            const center = new Box(annot.x, annot.y, annot.props.w, annot.props.h).center
            const inside = pointInPolygon(center, transform)
            if (inside && annot.meta.zoneID === shape.meta.zoneId)
              editor.updateShape({ ...annot, meta: { ...annot.meta, zoneID: '' } })
          }
        })
      }
    })
    editor.deleteShapes(shapes)
  }

  useHotkeys([Key.Delete, Key.Backspace], fn, {
    enableOnFormTags: ['input'],
    ignoreEventWhen(e) {
      return e.target instanceof HTMLInputElement && e.target.getAttribute('data-allow-hotkeys') !== 'true'
    },
  })

  return (
    <ToolbarTooltip
      relationship="description"
      kbd="⌫"
      content={t('annot.toolbar.delete')}
    >
      <ToolbarButton icon={<Delete20Regular />} onClick={fn}>
        {t('annot.toolbar.delete')}
      </ToolbarButton>
    </ToolbarTooltip>
  )
})
