import { useQuery } from '@tanstack/react-query'
import type { ZoneShapePartial } from '../../annot/polygon/zone/shape'
import { fromServerZone } from '../../annot/polygon/zone/shape'
import { server } from '../../util/data/server'
import { PAGE_ID } from '../state/id'

function toZones(metadata: string): ZoneShapePartial[] {
  if (metadata === '')
    return []
  const server = JSON.parse(metadata)

  let zones: ZoneShapePartial[] = []
  for (const [key, value] of Object.entries(server)) {
    if (key === 'zones' && Array.isArray(value))
      zones = value.map(zone => fromServerZone({ server: zone }))
  }
  return zones
}

export function usePageMetadata() {
  return useQuery({
    queryKey: ['metadata', PAGE_ID],
    queryFn: async () => {
      try {
        return await server.getPageMetadataDetail(PAGE_ID)
      }
      catch (e) {
        return { metadata: JSON.stringify({}) }
      }
    },
    select: data => toZones(data.metadata),
  })
}
